import * as Actions from "./actionTypes"

export const viewOrders = (archives = false) => ({
  type: archives ? Actions.SERVICE_ORDERS_VIEW_ARCHIVES : Actions.SERVICE_ORDERS_VIEW_ACTIVE
})

export const updateServiceOrders = orders => ({
  type: Actions.SERVICE_ORDERS_UPDATE,
  payload: orders
})
