import React from "react"
import ReturnsList from "./list"
// import WarrantyModal from "./modal";
// import WarrantyEditModal from "./editModal";
// import SearchForm from "./SearchForm";
import ReturnsNavigation from "./navigation"

const Returns = props => (
  <div className="content-section">
    <h3>Zwroty</h3>
    <ReturnsNavigation />
    {/* <SearchForm /> */}
    <ReturnsList />
    {/* <WarrantyModal /> */}
    {/* <WarrantyEditModal /> */}
  </div>
)

export default Returns
