import React from "react"
import { connect } from "react-redux"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { withFormik, Field } from "formik"
import { warrantyValidationSchema } from "../../forms/validators"
import SerialNumberField from "../../forms/fields/SerialNumberField"
import WarrantyTypeField from "../../forms/fields/WarrantyTypeField"
import PurchaseDateField from "../../forms/fields/PurchaseDateField"
import * as wActions from "../../store/actions/warranty"

const WarrantyModal = props => {
  const { isModalOpen, toggleModal, isValid, isSubmitting, handleSubmit } = props

  return (
    <Modal isOpen={isModalOpen} toggle={toggleModal} className="my-modal" fade={false}>
      <ModalHeader toggle={props.toggleModal}>Edycja gwarancji</ModalHeader>
      <ModalBody>
        <Field name="serialNumber" render={props => <SerialNumberField {...props} />} />
        <WarrantyTypeField {...props} />
        {/* <PurchaseDateField {...props} /> */}
        <Field name="purchaseDate" component={PurchaseDateField} />
      </ModalBody>
      <ModalFooter>
        <Button type="submit" color="primary" disabled={!isValid || isSubmitting} onClick={handleSubmit}>
          Zapisz
        </Button>{" "}
        <Button color="danger" onClick={props.toggleModal}>
          Anuluj
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const EnchancedModalForm = withFormik({
  mapPropsToValues: props => ({
    ...props.editFields,
    purchaseDate: props.editFields.purchaseDate ? new Date(props.editFields.purchaseDate.seconds * 1000) : new Date()
  }),
  validationSchema: warrantyValidationSchema,
  isInitialValid: true,
  handleSubmit: async (values, { props, setSubmitting }) => {
    setSubmitting(true)
    console.log(values)
    await props.updateWarranty(values)
    setSubmitting(false)
  },
  enableReinitialize: true,
  displayName: "WarrantyForm"
})(WarrantyModal)

const mapStateToProps = state => ({
  isModalOpen: state.warranty.editModalOpen,
  editFields: state.warranty.editFields
})

const mapDispatchToProps = dispatch => ({
  toggleModal: () => dispatch(wActions.toggleEditModal()),
  updateWarranty: data => dispatch(wActions.updateWarranty(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(EnchancedModalForm)
