import React from "react"
import { connect } from "react-redux"
import { updateServiceOrders } from "../../store/actions/serviceOrders"
import { db } from "../../firebase"

const ServiceOrderList = props => {
  React.useEffect(() => {
    monitorRecords()
  }, []) // eslint-disable-line

  const monitorRecords = () => {
    db.collection("serviceOrders").onSnapshot(function (querySnapshot) {
      var orders = []
      querySnapshot.forEach(function (doc) {
        orders.push(doc.data())
      })

      props.updateServiceOrders(orders)

      // console.log("Current service orders: ", orders);
    })
  }

  return (
    <React.Fragment>
      {props.loading ? (
        <div className="loading">Loading data...</div>
      ) : (
        <div>{`Ilosc zlecen: ${props.items.length}`}</div>
        // <Table bordered hover>
        //   <thead>
        //     <tr>
        //       <th>Numer seryjny</th>
        //       <th>Gwarancja</th>
        //       <th>Status</th>
        //       <th>Data wystawienia gwarancji</th>
        //       <th>Operacje</th>
        //     </tr>
        //   </thead>
        //   <tbody>
        //     {props.items.length > 0 ? (
        //       props.items.map(mapDataToRow)
        //     ) : (
        //       <tr>
        //         <td colSpan="5">Brak danych</td>
        //       </tr>
        //     )}
        //   </tbody>
        //   <tfoot>
        //     <tr>
        //       <td colSpan="5">Ilosc rekordow: {props.items.length}</td>
        //     </tr>
        //   </tfoot>
        // </Table>
      )}
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  items: state.serviceOrders.orders,
  loading: state.appState.loading
})

const mapDispatchToProps = dispatch => ({
  updateServiceOrders: orders => dispatch(updateServiceOrders(orders))
})

export default connect(mapStateToProps, mapDispatchToProps)(ServiceOrderList)
