import React from "react"
import { Badge } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPrint } from "@fortawesome/free-solid-svg-icons"
import usePrinterStatus from "hooks/usePrinterStatus"

import "./printStatus.css"

const PrinterStatus = () => {
  const isAvailable = usePrinterStatus()
  return (
    <div className="print-status">
      <FontAwesomeIcon icon={faPrint} color={isAvailable ? "#28a745" : "black"} className="print-icon" />
      {!isAvailable && <Badge color="warning">Niepodłączona</Badge>}
    </div>
  )
}

export default PrinterStatus
