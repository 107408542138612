import { useCallback, useState, useEffect } from "react"
import { Button, Input, Label, Form, FormGroup, Row, Col, Table } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChartColumn } from "@fortawesome/free-solid-svg-icons"
import { useForm, Controller } from "react-hook-form"
import useMongoStatus from "hooks/useMongoStatus"
import moment from "moment"
import API from "API"

import { ActionTypes } from "context/scannerProvider"
import { d } from "utils/dispatchHelper"
import useScannerContext from "hooks/useScannerContext"

import NavContainer from "components/NavContainer"

const RepairsHistory = () => {
  const [repairsList, setRepairsList] = useState([])
  const { control, handleSubmit } = useForm()
  const [repairsHistoryData, setRepairsHistoryData] = useState([])
  const isMongoOnline = useMongoStatus()
  const [state, dispatch] = useScannerContext()

  const now = moment().format("YYYY-MM-DD")
  const yesterday = moment().subtract(7, "days").format("YYYY-MM-DD")

  // const onSubmit = useCallback(async formData => {
  //   const { dateStart, dateEnd } = formData
  //   let data = []

  //   try {
  //     data = await API.fetchServicePartsUsageHistory(dateStart, dateEnd)
  //   } catch (err) {
  //     console.log("There was a problem fetching your request")
  //     data = []
  //   }

  //   setRepairsHistoryData(data)
  // }, [])

  const fetchRepairs = useCallback(async () => {
    const dispatchRepairs = repairs => dispatch(d(ActionTypes.SET_REPAIRSLIST, repairs))
    await API.fetchRepairs(dispatchRepairs)
  }, [dispatch])

  useEffect(() => {
    const { repairs } = state

    if (repairs) {
      // const items = prepareRepairs(repairs)
      setRepairsList(repairs)
      console.log(repairs)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.repairs])

  useEffect(() => {
    fetchRepairs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const Navigation = () => {
    return (
      <NavContainer>
        <Form /*onSubmit={handleSubmit(onSubmit)}*/>
          <Row>
            <Col md={5}>
              <FormGroup>
                <Label for="dateStart">Od:</Label>
                <Controller
                  name="dateStart"
                  control={control}
                  defaultValue={yesterday}
                  render={({ field }) => <Input type="date" {...field} max={now} />}
                />
              </FormGroup>
            </Col>
            <Col md={5}>
              <FormGroup>
                <Label for="dateEnd">Do:</Label>
                <Controller
                  name="dateEnd"
                  control={control}
                  defaultValue={now}
                  render={({ field }) => <Input type="date" {...field} max={now} />}
                />
              </FormGroup>
            </Col>
            <Col md={5}>
              <Button color="success" type="submit" disabled={isMongoOnline === false}>
                <FontAwesomeIcon icon={faChartColumn} /> Generuj raport
              </Button>
            </Col>
          </Row>
        </Form>
      </NavContainer>
    )
  }

  return (
    <div className="content-section service-parts-history-container">
      <h3>Raport przebiegu napraw</h3>
      {/* <Navigation /> */}
      <div>Ilość napraw: {repairsList.length}</div>
    </div>
  )
}

export default RepairsHistory
