import * as actions from "./actionTypes"

export const openModal = content => ({
  type: actions.MODAL_OPEN,
  payload: {
    content
  }
})

export const closeModal = () => ({
  type: actions.MODAL_CLOSE
})

export const toggleModal = err => ({
  type: actions.MODAL_TOGGLE
})
