import React from "react"
import { Button } from "reactstrap"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons"
import SerialNumberForm from "../../forms/SerialNumberForm"

import "./style.css"

const Header = props => {
  const { serialNumber } = props
  return (
    <div className="header-content">
      <Button tag={Link} to="/numery-seryjne" color="primary">
        <FontAwesomeIcon icon={faLongArrowAltLeft} />
        &nbsp; Powrót
      </Button>
      <h3>Edycja numeru seryjnego: {serialNumber}</h3>
    </div>
  )
}

const SerialNumbersEdit = props => {
  const { match } = props
  const { params } = match

  return (
    <div className="content-section">
      <Header serialNumber={params.id} />
      <SerialNumberForm serialNumber={params.id} />
    </div>
  )
}

export default SerialNumbersEdit
