import React, { useState, useCallback } from "react"
import { Button, NavItem, Toast, ToastBody, ToastHeader } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faStop, faBarcode } from "@fortawesome/free-solid-svg-icons"
import BarcodeReader from "react-barcode-reader"
import classNames from "classnames"
import styled from "styled-components"

import { servicePartsPeopleBarcodes, servicePartsBarcodeConfirmAction } from "constants/serviceParts"
import NavContainer from "components/NavContainer"
import { NewServicePartModal, UpdateServicePartModal } from "components/ServicePartsModal"
import ServicePartsHistory from "components/ServicePartsHistory"
import ServicePartsList from "components/ServicePartsList"
import ScannerPartWindow from "components/ScannerPartWindow"
import { ActionTypes } from "context/scannerProvider"
import { d } from "utils/dispatchHelper"
import API from "API"

import useAuthorContext from "hooks/useAuthorContext"
import useScannerContext from "hooks/useScannerContext"
import useKeyPress from "hooks/useKeyPress"

import styles from "./ServiceParts.module.scss"

const StyledNavItem = styled(NavItem)`
  margin-right: 0.4em;
`

const ServiceParts = () => {
  const [state, dispatch] = useScannerContext()
  const [author, updateAuthor] = useAuthorContext()

  const [isNewPartOpened, setIsNewPartOpened] = useState(false)
  const [isUpdatePartOpened, setIsUpdatePartOpened] = useState(false)
  const [updateProps, setUpdateProps] = useState(false)
  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState(false)

  const toggle = () => setIsNewPartOpened(!isNewPartOpened)
  const updateToggle = () => setIsUpdatePartOpened(!isUpdatePartOpened)

  useKeyPress("l", () => handleScan("1001"), true)
  useKeyPress("p", () => handleScan("PERSON-S1"), true)

  const toggleToast = useCallback(
    event => {
      event.preventDefault()
      setShowToast(!showToast)
      setToastMessage(false)
    },
    [showToast]
  )

  const handleToastAlert = useCallback((msg, showAlert = true) => {
    setToastMessage(msg)
    setShowToast(showAlert)
  }, [])

  const handleError = () => {}
  const handleNewPartModalCallback = useCallback(() => setIsNewPartOpened(true), [])

  const handleIsScanningCallback = useCallback(
    event => {
      event.preventDefault()
      dispatch({ type: ActionTypes.TOGGLE_SCANNING })
    },
    [dispatch]
  )

  const handlePartUpdate = useCallback(event => {
    event.preventDefault()
    const { partname, partcode, partcount } = event.target.dataset

    setUpdateProps({
      partName: partname,
      partCode: partcode,
      partCount: partcount
    })
    setIsUpdatePartOpened(true)
  }, [])

  const handlePartsSave = useCallback(
    async (operationType = "REMOVE") => {
      const { partCode, partName, partCount, scannedPartCount } = state
      let newPartCount = 0
      let operationName = null

      switch (operationType) {
        case "UPDATE":
          newPartCount = partCount + scannedPartCount
          operationName = "UPDATE"
          break
        case "REMOVE":
        default:
          newPartCount = partCount - scannedPartCount
          operationName = "REMOVE"
      }

      // console.log("handleParts save", partCount, newPartCount, scannedPartCount)

      const partUpdate = await API.updateServicePart(partCode, partName, newPartCount, author)
      const historyUpdate = await API.addServicePartHistory(
        partCode,
        partName,
        operationName,
        newPartCount,
        author,
        partCount
      )

      if (partUpdate && historyUpdate) {
        dispatch(d(ActionTypes.SAVE_AND_REFRESH))
      }
    },
    [state, author, dispatch]
  )

  const handleScannedCode = useCallback(
    async data => {
      //  open scanner window
      if (state.isScanOpen === false) {
        dispatch(d(ActionTypes.SCANNER_OPEN, data))
      }
      //  dont add more than stock
      if (state.scannedPartCount < state.partCount) {
        dispatch(d(ActionTypes.SCAN_PART))
      }
    },
    [state, dispatch]
  )

  const checkForPersonInCode = useCallback(code => {
    const scannedCode = code.replace("/", "-")
    for (let personBarcode of servicePartsPeopleBarcodes) {
      if (personBarcode.barcode === scannedCode) {
        return personBarcode
      }
    }
    return false
  }, [])

  const checkServicePartIdIfExists = useCallback(
    code => {
      if (state.serviceParts.length === 0) {
        return false
      }

      return state.serviceParts.filter(e => e.id === code).reduce((p, cur) => cur, false)
    },
    [state.serviceParts]
  )

  const handleScan = useCallback(
    async code => {
      if (!state.isScanning) {
        console.log("Scanning is OFF!!!")
        return
      }

      //  check if want to save scanning window
      if (
        state.isScanning &&
        state.isScanOpen &&
        state.scannedPartCount > 0 &&
        code === servicePartsBarcodeConfirmAction
      ) {
        // console.log("handle scan", state, code)
        handlePartsSave()
      }

      const scanningPerson = checkForPersonInCode(code)

      //  if nobody is set as scanning then check if the scanned code is a personal barcode
      if (author === false || author === "") {
        //  we have a person, setup this person as scanner
        if (scanningPerson !== false) {
          updateAuthor(scanningPerson?.name)
          handleToastAlert(false, false)
          return
        } else {
          handleToastAlert("Nie wybrano skanującej osoby")
          return
        }
      } else if (author !== false && scanningPerson !== false) {
        updateAuthor(scanningPerson?.name)
        return
      }

      const codeExistsWithData = await checkServicePartIdIfExists(code)
      if (codeExistsWithData === false) {
        console.log("code %s does not exists within the DB", code)
        return
      }

      handleScannedCode(codeExistsWithData)
    },
    [
      state,
      checkForPersonInCode,
      author,
      checkServicePartIdIfExists,
      handleScannedCode,
      handlePartsSave,
      updateAuthor,
      handleToastAlert
    ]
  )

  const ToastMessage = props => {
    return (
      toastMessage && (
        <Toast className={styles.toastAlert}>
          <ToastHeader icon="danger" toggle={toggleToast}>
            Informacja
          </ToastHeader>
          <ToastBody>{toastMessage}</ToastBody>
        </Toast>
      )
    )
  }

  const ScanningAsInfo = () => {
    return (
      <span className={classNames([styles.scanningInfo, "btn"])}>
        Skanujesz jako: <strong>{author ? author : <code>Brak</code>}</strong>
      </span>
    )
  }

  const Navigation = () => {
    return (
      <NavContainer>
        <ToastMessage />
        <StyledNavItem>
          <Button color="success" onClick={handleNewPartModalCallback}>
            <FontAwesomeIcon icon={faPlus} /> Dodaj Część
          </Button>
        </StyledNavItem>

        <StyledNavItem>
          <Button color={state.isScanning ? "danger" : "info"} onClick={handleIsScanningCallback}>
            <FontAwesomeIcon icon={state.isScanning ? faStop : faBarcode} />{" "}
            {state.isScanning ? "Zatrzymaj skanowanie" : "Skanuj części z magazynu"}
          </Button>
        </StyledNavItem>
        <StyledNavItem>
          <ScanningAsInfo />
        </StyledNavItem>
      </NavContainer>
    )
  }

  return (
    <div className="content-section service-parts-container">
      <h3>Części</h3>
      <Navigation />
      <BarcodeReader onError={handleError} onScan={handleScan} minLength={3} />
      <div className={styles.servicePartsCointaner}>
        <div className={styles.serviceParts}>
          <h5>Lista części</h5>
          <ServicePartsList updatePartCountCallback={handlePartUpdate} />
        </div>
        <div className={styles.servicePartsHistory}>
          <h5>Historia części</h5>
          <ServicePartsHistory />
        </div>
      </div>
      <div className={styles.localCache}>Local cache: {state.serviceParts.length}</div>
      <NewServicePartModal isOpen={isNewPartOpened} toggle={toggle} />
      <UpdateServicePartModal isOpen={isUpdatePartOpened} toggle={updateToggle} updateProps={updateProps} />
      <ScannerPartWindow handlePartsSave={handlePartsSave} />
    </div>
  )
}

export default ServiceParts
