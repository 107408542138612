import * as actions from "./actionTypes"
import * as AppState from "./appState"

export const fetchItems = () => async dispatch =>
  dispatch(AppState.loading())
    .then(() =>
      dispatch({
        type: actions.FETCH_SERIAL_NUMBERS_V2,
        meta: {
          collection: "serialNumbers"
        }
      })
    )
    .then(() => dispatch(AppState.done()))

export const getItem = id => async dispatch =>
  dispatch(AppState.loading())
    .then(() =>
      dispatch({
        type: actions.GET_SERIAL_NUMBER,
        meta: {
          method: "findOne",
          collection: "serialNumbers",
          docId: id
        }
      })
    )
    .then(() => dispatch(AppState.done()))

export const editItem = item => dispatch => {
  return dispatch({
    type: actions.MODAL_OPEN,
    payload: {
      content: item
    }
  })
}

export const saveItem = data => async dispatch =>
  dispatch(AppState.loading())
    .then(() =>
      dispatch({
        type: actions.SAVE_SERIAL_NUMBERS,
        payload: data,
        meta: {
          method: "save",
          collection: "generatedNumbers"
        }
      })
    )
    .then(() => dispatch(AppState.done()))
