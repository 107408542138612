import React from "react"
import { connect } from "react-redux"
import { Form, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { withFormik, Field } from "formik"
import { warrantyValidationSchema } from "../../forms/validators"
import SerialNumberField from "../../forms/fields/SerialNumberField"
import WarrantyTypeField from "../../forms/fields/WarrantyTypeField"
import PurchaseDateField from "../../forms/fields/PurchaseDateField"
import { productWarranty } from "../../constants/product"
import * as wActions from "../../store/actions/warranty"

const initialFormValues = {
  serialNumber: "",
  warrantyLength: productWarranty[0].count,
  purchaseDate: undefined
}

const WarrantyModal = props => {
  const { values, isValid, isModalOpen, toggleModal, resetForm } = props
  const serialNumberRef = React.createRef(null)

  const saveWarranty = () => {
    props.saveWarranty(values)
  }

  return (
    <Modal
      isOpen={isModalOpen}
      toggle={toggleModal}
      className="my-modal"
      fade={false}
      onOpened={() => serialNumberRef.current.focus()}
      onClosed={() => resetForm(initialFormValues)}
    >
      <ModalHeader toggle={toggleModal}>Nowa gwarancja</ModalHeader>
      <ModalBody>
        <Form>
          <Field name="serialNumber" render={props => <SerialNumberField {...props} innerRef={serialNumberRef} />} />
          <WarrantyTypeField {...props} />
          <Field name="purchaseDate" component={PurchaseDateField} />
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button type="submit" color="primary" disabled={!isValid} onClick={saveWarranty}>
          Dodaj
        </Button>{" "}
        <Button color="danger" onClick={props.toggleModal}>
          Anuluj
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const EnchancedModalForm = withFormik({
  mapPropsToValues: props => initialFormValues,
  validationSchema: warrantyValidationSchema,
  enableReinitialize: true,
  displayName: "WarrantyForm"
})(WarrantyModal)

const mapStateToProps = state => ({
  isModalOpen: state.warranty.isModalOpen
})

const mapDispatchToProps = dispatch => ({
  toggleModal: () => dispatch(wActions.toggleModal()),
  saveWarranty: data => dispatch(wActions.saveWarranty(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(EnchancedModalForm)
