import React from "react"
import { connect } from "react-redux"
import { Nav, NavItem, NavLink, Button } from "reactstrap"
import styled from "styled-components"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { viewOrders } from "../../store/actions/serviceOrders"

const DivContainer = styled.div`
  margin: 10px 0;
`

const ServiceOrderNavigation = props => {
  return (
    <div>
      <DivContainer>
        <Button color="success" onClick={props.openModal}>
          <FontAwesomeIcon icon={faPlus} /> Nowe zlecenie
        </Button>
      </DivContainer>
      <DivContainer>
        <Nav tabs>
          <NavItem>
            <NavLink className={classNames({ active: props.activeOrders })} onClick={props.viewActiveOrders}>
              Aktywne
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={classNames({ active: props.archivedOrders })} onClick={props.viewArchiveOrders}>
              Archiwalne
            </NavLink>
          </NavItem>
        </Nav>
      </DivContainer>
    </div>
  )
}

const mapPropsToState = state => ({
  activeOrders: state.serviceOrders.viewActiveOrders,
  archivedOrders: !state.serviceOrders.viewActiveOrders
})

const mapDispatchToProps = dispatch => ({
  viewActiveOrders: () => dispatch(viewOrders()),
  viewArchiveOrders: () => dispatch(viewOrders(true))
})

export default connect(mapPropsToState, mapDispatchToProps)(ServiceOrderNavigation)
