import { createRoot } from "react-dom/client"
import App from "./App"
import * as serviceWorker from "./serviceWorker"

import "./index.css"

const container = document.getElementById("root")
const root = createRoot(container)
root.render(<App />)

// ReactDOM.render(<App />, document.getElementById("root"))

// console.log("Envy", process.env)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register()
serviceWorker.unregister()
