import React from "react"
import {
  Modal as RModal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
  // Input
} from "reactstrap"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import * as actions from "../../store/actions/modal"

const Modal = ({ isShowing, toggle, title, content, submitTitle, modalOnSubmit = toggle }) => (
  <>
    <RModal isOpen={isShowing} fade={false} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>{isShowing && content}</ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={modalOnSubmit}>
          {submitTitle}
        </Button>
      </ModalFooter>
    </RModal>
  </>
)

Modal.propTypes = {
  modalTitle: PropTypes.string,
  isShowing: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  modalSubmitButtonTitle: PropTypes.string,
  modalOnSubmit: PropTypes.func
}

const mapStateToProps = state => ({
  isShowing: state.modal.isShowing,
  content: state.modal.content,
  title: state.modal.title || "Formularz",
  submitTitle: state.modal.submitTitle || "Zamknij"
})

const mapDispatchToProps = dispatch => ({
  toggle: () => dispatch(actions.toggleModal())
})

export default connect(mapStateToProps, mapDispatchToProps)(Modal)
