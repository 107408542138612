import * as actions from "../actions/actionTypes"

const initialState = {
  available: false,
  isPrinting: false,
  printing: [],
  printError: null
}

const printerReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.PRINTER_ONLINE:
      return {
        ...state,
        available: true
      }
    case actions.PRINTER_OFFLINE:
      return {
        ...state,
        available: false
      }
    case actions.PRINTER_START_PRINT:
      return {
        ...state,
        printing: [...state.printing, payload],
        isPrinting: true
      }
    case actions.PRINTER_FINISH_PRINT:
      return {
        ...state,
        printing: state.printing.filter(x => x !== payload),
        isPrinting: state.printing.filter(x => x !== payload).length > 0
      }
    case actions.PRINTER_ERROR:
      return {
        ...state,
        printing: [],
        isPrinting: false,
        printError: payload.error.message
      }

    default:
      return state
  }
}

export default printerReducer
