import React from "react"
import { connect } from "react-redux"
import { Button } from "reactstrap"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { openModal } from "../../store/actions/warranty"

const NavContainer = styled.div`
  margin: 10px 0;
`

const WarrantyNavigation = props => {
  return (
    <NavContainer>
      <Button color="success" onClick={props.openModal}>
        <FontAwesomeIcon icon={faPlus} /> Nowe gwarancja
      </Button>
    </NavContainer>
  )
}

const mapDispatchToProps = dispatch => ({
  openModal: () => dispatch(openModal())
})

export default connect(null, mapDispatchToProps)(WarrantyNavigation)
