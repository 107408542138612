import { useCallback, useState } from "react"
import { Button, Input, Label, Form, FormGroup, Row, Col, Table } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChartColumn } from "@fortawesome/free-solid-svg-icons"
import { useForm, Controller } from "react-hook-form"
import useMongoStatus from "hooks/useMongoStatus"
import moment from "moment"
import API from "API"

import NavContainer from "components/NavContainer"

const ServiceParts = () => {
  const { control, handleSubmit } = useForm()
  const [repairsHistoryData, setRepairsHistoryData] = useState([])
  const isMongoOnline = useMongoStatus()

  const now = moment().format("YYYY-MM-DD")
  const yesterday = moment().subtract(7, "days").format("YYYY-MM-DD")

  const onSubmit = useCallback(async formData => {
    const { dateStart, dateEnd } = formData
    let data = []

    try {
      data = await API.fetchServicePartsUsageHistory(dateStart, dateEnd)
    } catch (err) {
      console.log("There was a problem fetching your request")
      data = []
    }

    setRepairsHistoryData(data)
  }, [])

  const Navigation = () => {
    return (
      <NavContainer>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md={5}>
              <FormGroup>
                <Label for="dateStart">Od:</Label>
                <Controller
                  name="dateStart"
                  control={control}
                  defaultValue={yesterday}
                  render={({ field }) => <Input type="date" {...field} max={now} />}
                />
              </FormGroup>
            </Col>
            <Col md={5}>
              <FormGroup>
                <Label for="dateEnd">Do:</Label>
                <Controller
                  name="dateEnd"
                  control={control}
                  defaultValue={now}
                  render={({ field }) => <Input type="date" {...field} max={now} />}
                />
              </FormGroup>
            </Col>
            <Col md={5}>
              <Button color="success" type="submit" disabled={isMongoOnline === false}>
                <FontAwesomeIcon icon={faChartColumn} /> Generuj raport
              </Button>
            </Col>
          </Row>
        </Form>
      </NavContainer>
    )
  }

  return (
    <div className="content-section service-parts-history-container">
      <h3>Raport użycia części zamiennych</h3>
      <Navigation />
      <div className="repairs-history-table">
        {repairsHistoryData.length > 0 && (
          <Table bordered hover striped>
            <thead>
              <tr>
                <th>Kod</th>
                <th>Nazwa</th>
                <th>Ilość</th>
              </tr>
            </thead>
            <tbody>
              {repairsHistoryData.map(({ _id: { partName, partCode }, itemsCount }) => (
                <tr key={partCode}>
                  <td>{partCode}</td>
                  <td>{partName}</td>
                  <td>{itemsCount}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
    </div>
  )
}

export default ServiceParts
