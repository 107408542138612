import React, { createContext } from "react"
import useStickyState from "hooks/useStickyState"

const AuthorContext = createContext()

const AuthorProvider = ({ children }) => {
  const [author, setAuthor] = useStickyState(false, "personScanningAs")

  return <AuthorContext.Provider value={[author, setAuthor]}>{children}</AuthorContext.Provider>
}

export { AuthorProvider, AuthorContext }
