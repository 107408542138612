import { APP_LOADING, APP_LOADING_DONE, APP_LOADING_ERROR, HANDLE_USER_STATE } from "../actions/actionTypes"

const initialState = {
  loading: false,
  error: false,
  loggedIn: false,
  user: null
}

const appState = (state = initialState, { type, payload }) => {
  switch (type) {
    case APP_LOADING:
      return {
        ...state,
        loading: true
      }

    case APP_LOADING_DONE:
      return {
        ...state,
        loading: false
      }

    case APP_LOADING_ERROR:
      return {
        ...state,
        loading: false,
        error: payload
      }
    case HANDLE_USER_STATE:
      return {
        ...state,
        loggedIn: payload ? true : false,
        user: payload ? payload : null,
        error: false
      }

    default:
      return state
  }
}

export default appState
