import React from "react"
import { connect } from "react-redux"
import { Spinner } from "reactstrap"

import "./style.css"

const Loader = props => {
  const { loading } = props
  return (
    <React.Fragment>
      {loading === true && (
        <div className="loader">
          <div className="spinner-container">
            <Spinner color="light" style={{ width: "4rem", height: "4rem" }} />
          </div>
          <div className="modal-backdrop fade show" />
        </div>
      )}
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  loading: state.appState.loading
})

export default connect(mapStateToProps)(Loader)
