import React from "react"
import ServiceOrderList from "./list"
// import WarrantyModal from "./modal";
// import SearchForm from "./SearchForm";
import ServiceOrderNavigation from "./ServiceOrderNavigation"

const ServiceOrders = props => (
  <div className="content-section">
    <h3>Lista zleceń serwisowych</h3>
    <ServiceOrderNavigation />
    {/* <SearchForm /> */}
    <ServiceOrderList />
    {/* <WarrantyModal /> */}
  </div>
)

export default ServiceOrders
