import React from "react"
import { withFormik } from "formik"
import * as yup from "yup"
import PropTypes from "prop-types"
import { Col, Form, FormGroup, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { productModels, productColors, productBatch } from "../../constants/product"

const initialValues = {
  productModel: productModels[0].serialPart,
  productColor: productColors[0].serialPart.toString(),
  productBatch: productBatch[0].toString(),
  serialNumber: ""
}

const validationSchema = yup.object().shape({
  productModel: yup.string().required(),
  productColor: yup.string().required(),
  productBatch: yup.string().required(),
  serialNumber: yup.string().required().min(3).max(7).matches(/\d+/)
})

const SerialNumberPopover = props => {
  const serialNumberRef = React.useRef(null)
  const {
    values,
    errors,
    handleChange,
    handleBlur,
    isValid,
    resetForm,
    isPopoverOpen = false,
    popOverToggle,
    onSerialGeneration
  } = props

  const generateSerial = () => {
    const { productModel, productColor, productBatch, serialNumber } = values
    const paddedNumber = ("0000000" + serialNumber).substring(serialNumber.length)
    const generatedSerial = `${productModel}${productBatch}${productColor}${paddedNumber}`
    onSerialGeneration(generatedSerial)
    popOverToggle()
  }

  return (
    <Modal
      isOpen={isPopoverOpen}
      toggle={popOverToggle}
      trigger="click"
      fade={false}
      size="lg"
      onClosed={() => resetForm(initialValues)}
      onOpened={() => serialNumberRef.current.focus()}
    >
      <ModalHeader>Generator numeru seryjnego</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup row>
            <Label for="productModel" sm={2}>
              Model
            </Label>
            <Col sm={10}>
              {productModels.map(({ serialPart, title }) => (
                <FormGroup check key={serialPart} inline>
                  <Label>
                    <Input
                      type="radio"
                      name="productModel"
                      id={serialPart}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={serialPart}
                      autoComplete="off"
                      defaultChecked={serialPart === productModels[0].serialPart}
                    />{" "}
                    {title}
                  </Label>
                </FormGroup>
              ))}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="productColor" sm={2}>
              Kolor
            </Label>
            <Col sm={10}>
              {productColors.map(({ name, serialPart }) => (
                <FormGroup check key={serialPart} inline>
                  <Label>
                    <Input
                      type="radio"
                      name="productColor"
                      id={serialPart}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={serialPart}
                      autoComplete="off"
                      defaultChecked={serialPart === productColors[0].serialPart}
                    />{" "}
                    {name}
                  </Label>
                </FormGroup>
              ))}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="productBatch" sm={2}>
              Nr partii
            </Label>
            <Col sm={10}>
              {productBatch.map(value => (
                <FormGroup check key={value} inline>
                  <Label>
                    <Input
                      type="radio"
                      name="productBatch"
                      id={value}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={value}
                      autoComplete="off"
                      defaultChecked={value === 0}
                    />
                    {` ${value}`}
                  </Label>
                </FormGroup>
              ))}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="serialNumber" sm={2}>
              Nr seryjny
            </Label>
            <Col sm={10}>
              <FormGroup check inline>
                <Label>
                  <Input
                    innerRef={serialNumberRef}
                    type="text"
                    name="serialNumber"
                    placeholder="aktualny numer seryjny"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.serialNumber}
                    autoComplete="off"
                    maxLength="7"
                    valid={errors["serialNumber"] === undefined}
                    invalid={errors["serialNumber"] !== undefined || values.serialNumber === ""}
                  />
                </Label>
              </FormGroup>
            </Col>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button type="submit" color="primary" disabled={!isValid} onClick={generateSerial}>
          Generuj
        </Button>{" "}
        <Button color="danger" onClick={popOverToggle}>
          Anuluj
        </Button>
      </ModalFooter>
    </Modal>
  )
}

SerialNumberPopover.propTypes = {
  isPopoverOpen: PropTypes.bool.isRequired,
  popOverToggle: PropTypes.func.isRequired,
  onSerialGeneration: PropTypes.func.isRequired
}

const EnchancedSerialNumberPopover = withFormik({
  mapPropsToValues: () => initialValues,
  validationSchema: validationSchema,
  displayName: "SerialNumberWizardForm"
})(SerialNumberPopover)

export default EnchancedSerialNumberPopover
