import * as actions from "../actions/actionTypes"
import { printerStartPrint, printerFinishPrint, printerError } from "../actions/printer"

const PRINTER_HOST = process.env.REACT_APP_PRINTER_HOST

const printerMiddleware = store => next => async action => {
  if (action.type === actions.PRINTER_PRINT_SERIAL) {
    const serialNumber = action.payload
    const { dispatch } = store

    dispatch(printerStartPrint(serialNumber))

    try {
      const response = await fetch(`${PRINTER_HOST}/print/${serialNumber}/2`, {
        method: "POST"
      })

      if (response.status !== 200) {
        throw new Error("Bad parameters for the print")
      }

      dispatch(printerFinishPrint(serialNumber))
    } catch (err) {
      dispatch(printerError(serialNumber, err))
    }
  } else if (action.type === actions.PRINTER_PRINT_BATCH) {
    const serialNumbers = action.payload
    const { dispatch } = store

    dispatch(printerStartPrint(serialNumbers))

    try {
      const response = await fetch(`${PRINTER_HOST}/printBatch`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          serialNumbers: serialNumbers
          // serialNumbers: ["3031300000015", "3031300000022"]
        })
      })

      if (response.status !== 200) {
        throw new Error("Bad parameters for the batch print")
      }

      dispatch(printerFinishPrint(serialNumbers))
    } catch (err) {
      dispatch(printerError(serialNumbers, err))
    }
  }

  next(action)
}

export default printerMiddleware
