import React from "react"
import { Table, Badge, Button } from "reactstrap"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import moment from "moment"

import WarrantyEditModal from "./editModal"
import {
  fetchWarranties,
  findBySerialNumber,
  warrantiesFetched,
  changeItemsPerPage,
  editWarranty
} from "../../store/actions/warranty"
import { productWarranty } from "../../constants/product"

import PrintButton from "../PrintButton"

const WarrantyList = props => {
  const { editWarranty } = props

  React.useEffect(() => {
    props.fetchItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.itemsPerPage])

  const ListActionItem = item => {
    return (
      <>
        <Button color="primary" onClick={() => editWarranty(item)} size="sm">
          Edytuj
        </Button>{" "}
        <PrintButton barcode={item.serialNumber} />
      </>
    )
  }

  let previousRow = null

  const mapDataToRow = data => {
    let dateMarkerRow = null
    const { serialNumber, warrantyLength, purchaseDate } = data
    const { title } = productWarranty.filter(item => item.count === parseInt(warrantyLength)).pop()
    const warrantyDate = moment.unix(purchaseDate.seconds)
    const warrantyDateLength = moment.unix(purchaseDate.seconds).add(warrantyLength, "months")
    const warrantyDateFormated = warrantyDate.format("DD/MM/YYYY HH:mm")
    const currentDateRow = warrantyDate.format("DD/MM/YYYY")
    const warrantyIsActive = moment().isSameOrBefore(warrantyDateLength)
    if (previousRow !== currentDateRow) {
      dateMarkerRow = (
        <tr className="dateMarker" style={{ backgroundColor: "transparent" }}>
          <td
            colSpan={5}
            style={{
              fontWeight: "bold",
              textAlign: "center",
              borderBottom: "1px solid #999",
              fontSize: "110%"
            }}
          >
            {warrantyDate.format("dddd - DD/MM/YYYY")}
          </td>
        </tr>
      )
    }
    previousRow = currentDateRow

    return (
      <React.Fragment key={serialNumber}>
        {dateMarkerRow}
        <tr>
          <td>
            <Link to={`/reports/repairs-history/${serialNumber}`}>{serialNumber}</Link>
          </td>
          <td>
            <Badge color="secondary">{title}</Badge>
          </td>
          <td>
            <Badge color={warrantyIsActive ? "success" : "danger"}>{warrantyIsActive ? "Aktywna" : "Wygasła"}</Badge>
          </td>
          <td>{warrantyDateFormated}</td>
          <td>
            <ListActionItem {...data} />
          </td>
        </tr>
      </React.Fragment>
    )
  }

  return (
    <>
      {props.loading ? (
        <div className="loading">Loading data...</div>
      ) : (
        <>
          <Table bordered hover striped>
            <thead>
              <tr>
                <th>Numer seryjny</th>
                <th>Gwarancja</th>
                <th>Status</th>
                <th>Data wystawienia gwarancji</th>
                <th>Operacje</th>
              </tr>
            </thead>
            <tbody>
              {props.items.length > 0 ? (
                props.items.map(mapDataToRow)
              ) : (
                <tr>
                  <td colSpan="5">Brak danych</td>
                </tr>
              )}
            </tbody>
          </Table>
        </>
      )}
      <WarrantyEditModal />
    </>
  )
}

const mapStateToProps = state => ({
  items: state.warranty.items,
  itemsPerPage: state.warranty.itemsPerPage,
  loading: state.appState.loading
})

const mapDispatchToProps = dispatch => ({
  findBySerialNumber: serialNumber => dispatch(findBySerialNumber(serialNumber)),
  fetchItems: () => dispatch(fetchWarranties()),
  warrantiesFetched: items => dispatch(warrantiesFetched(items)),
  changeItemsPerPage: itemsPerPage => dispatch(changeItemsPerPage(itemsPerPage)),
  editWarranty: warrantyData => dispatch(editWarranty(warrantyData))
})

export default connect(mapStateToProps, mapDispatchToProps)(WarrantyList)
