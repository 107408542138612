import * as actions from "../actions/actionTypes"
import { defaultItemsPerPage } from "../../constants/ui"

const initialState = {
  items: [],
  itemsPerPage: defaultItemsPerPage
}

const serialNumbers = (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.FETCH_GENERATED_SERIALS:
      return {
        ...state,
        items: payload
      }
    case actions.FETCH_SERIAL_NUMBER:
      return {
        ...state,
        items: payload
      }

    case actions.SAVE_SERIAL_NUMBERS:
      return {
        ...state
      }

    default:
      return state
  }
}

export default serialNumbers
