import React from "react"
import { Button } from "reactstrap"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons"

import "./style.css"

const SerialNumbersSingle = props => {
  return <div>Hello world</div>
}

const Header = props => {
  const { match } = props
  const { params } = match

  return (
    <div className="header-content">
      <Button tag={Link} to="/numery-seryjne" color="primary">
        <FontAwesomeIcon icon={faLongArrowAltLeft} />
        &nbsp; Powrót
      </Button>
      {/* <div>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/numery-seryjne">Numery seryjne</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>{params.id}</BreadcrumbItem>
        </Breadcrumb>
      </div> */}
      <h3>Podgląd numeru seryjnego: {params.id}</h3>
    </div>
  )
}

const SerialNumbersView = props => {
  const { match } = props

  return (
    <div className="content-section">
      <Header match={match} />
      {/* <ServiceOrderNavigation /> */}
      {/* <SearchForm /> */}
      <SerialNumbersSingle />
      {/* <WarrantyModal /> */}
    </div>
  )
}

export default SerialNumbersView
