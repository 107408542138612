import React from "react"
import { Button } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons"
import { auth } from "firebase.js"

import "./userActions.scss"

const UserActions = props => {
  const user = auth.currentUser
  const { email, photoURL, displayName } = user

  const handleSignOutUser = () => auth.signOut()

  return (
    <div className="d-inline-flex flex-md-row justify-content-end align-items-center user-status">
      <img className="profile-picture" src={photoURL} width={32} height={32} alt={email} />
      <span className="ms-2 profile-name">{displayName}</span>
      <Button outline color="secondary" onClick={handleSignOutUser} size="sm" className="logout-btn">
        <FontAwesomeIcon icon={faSignOutAlt} />
      </Button>
    </div>
  )
}

export default UserActions
