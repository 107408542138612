import API from "../../API"
import * as actions from "./actionTypes"
import * as AppState from "./appState"

export const fetchItems = () => async dispatch =>
  dispatch(AppState.loading())
    .then(() =>
      dispatch({
        type: actions.FETCH_GENERATED_SERIALS,
        meta: {
          collection: "generatedNumbers"
        }
      })
    )
    .then(() => dispatch(AppState.done()))

export const getItem = id => async dispatch =>
  dispatch(AppState.loading())
    .then(() =>
      dispatch({
        type: actions.FETCH_SERIAL_NUMBER,
        meta: {
          collection: "generatedNumbers",
          searchKey: "id",
          searchTerm: id
        }
      })
    )
    .then(() => dispatch(AppState.done()))

export const saveItem = data => async dispatch =>
  dispatch(AppState.loading())
    .then(() =>
      dispatch({
        type: actions.SAVE_SERIAL_NUMBERS,
        payload: data,
        meta: {
          method: "save",
          collection: "generatedNumbers"
        }
      })
    )
    .then(() => dispatch(AppState.done()))

export const updateSerialEvents = data => async dispatch => {
  dispatch(AppState.loading())
  try {
    // console.log(data)
    await API.addEventToSerialNumber(data)
  } catch (err) {
    console.log(err)
  }
  dispatch(AppState.done())
}
