import {
  GET_WARRANTY_ITEMS_SUCCESS,
  EDIT_WARRANTY_ITEM,
  WARRANTY_MODAL_OPEN,
  WARRANTY_MODAL_CLOSE,
  WARRANTY_MODAL_TOGGLE,
  WARRANTY_MODAL_EDIT_TOGGLE,
  WARRANTY_CHANGE_ITEMS_PER_PAGE,
  WARRANTY_FETCH_BY_CURSOR,
  GET_WARRANTIES,
  PAGINATE_WARRANTIES,
  FIND_WARRANTY
} from "../actions/actionTypes"

const initialState = {
  isModalOpen: false,
  editModalOpen: false,
  editFields: false,
  items: [],
  firstItem: null,
  itemsPerPage: 25
}

const warrantyReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_WARRANTY_ITEMS_SUCCESS:
    case GET_WARRANTIES:
    case PAGINATE_WARRANTIES:
    case FIND_WARRANTY:
      return {
        ...state,
        items: payload,
        firstItem: state.firstItem === null ? payload[0] : state.firstItem
      }
    case WARRANTY_MODAL_OPEN:
      return {
        ...state,
        isModalOpen: true
      }
    case WARRANTY_MODAL_CLOSE:
      return {
        ...state,
        isModalOpen: false
      }
    case WARRANTY_MODAL_TOGGLE:
      return {
        ...state,
        isModalOpen: !state.isModalOpen
      }
    case WARRANTY_MODAL_EDIT_TOGGLE:
      return {
        ...state,
        editModalOpen: !state.editModalOpen
      }
    case WARRANTY_CHANGE_ITEMS_PER_PAGE:
      return {
        ...state,
        firstItem: null,
        itemsPerPage: payload
      }
    case WARRANTY_FETCH_BY_CURSOR:
      return {
        ...state,
        cursor: payload
      }
    case EDIT_WARRANTY_ITEM:
      return {
        ...state,
        editModalOpen: true,
        editFields: { ...payload }
      }
    default:
      return state
  }
}

export default warrantyReducer
