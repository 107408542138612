import React from "react"
import { Badge } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDatabase } from "@fortawesome/free-solid-svg-icons"
import useMongoStatus from "hooks/useMongoStatus"

import "./dbStatus.css"

const MongoDBStatus = () => {
  const isOnline = useMongoStatus()
  return (
    <div className="mongodb-status">
      <FontAwesomeIcon icon={faDatabase} color={isOnline ? "#28a745" : "#999"} className="db-icon" />{" "}
      {!isOnline && (
        <Badge className="ms-2" color="warning">
          Brak połączenia
        </Badge>
      )}
    </div>
  )
}

export default MongoDBStatus
