import { useState, useCallback, useEffect } from "react"
import PropTypes from "prop-types"
import { Button, Table, Badge } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPaperPlane, faWrench, faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import moment from "moment"
import API from "API"
import parseSerialNumber from "utils/serialNumberParser"

import RepairStats from "components/RepairsStats"

import { ActionTypes } from "context/scannerProvider"
import { d } from "utils/dispatchHelper"
import { repairsStatuses, mapStatusToColor, mapStatusToName, createdShortDateFormat } from "constants/repairs"

import useScannerContext from "hooks/useScannerContext"
import useStickyState from "hooks/useStickyState"

import "./style.scss"

const BadgeStyle = {
  fontSize: "11pt"
}

const RepairsList = ({ onFetchCallback, setEditRepairId }) => {
  const [repairsList, setRepairsList] = useState([])
  const [showDoneRepairs, setShowDoneRepairs] = useStickyState(true, "show-done-repairs")
  const [state, dispatch] = useScannerContext()
  const [dropdownList, setDropdownList] = useState({})
  const toggleDoneCallback = () => setShowDoneRepairs(!showDoneRepairs)

  const handleRepairEditCallback = useCallback(
    async event => {
      event.preventDefault()
      const { repairid } = event.target.dataset

      if (repairid !== undefined) {
        setEditRepairId(repairid)
      }
      // const row = state.repairs.find(repair => repair.id === repairid)

      // if (row !== undefined) {
      // console.log("found row", row)
      // }
    },
    [setEditRepairId]
  )

  const handleRepairUpdateCallback = useCallback(async event => {
    event.preventDefault()

    const { repairid, updatedstatus } = event.target.dataset

    const disableButton = (disabled = false) => {
      event.target.disabled = disabled
    }

    if (repairid && updatedstatus) {
      disableButton(true)
      await API.updateRepair(repairid, updatedstatus, null, null, disableButton(false))
    }
  }, [])

  const calculateDurations = (status, updatedAt = null, doneAt = null) => {
    switch (status) {
      case repairsStatuses.IN_PROGRESS:
        const diff = moment.unix(updatedAt.seconds).diff(moment())
        const durration = moment.duration(diff)
        return durration.humanize()
      case repairsStatuses.DONE:
      case repairsStatuses.SENT:
      case repairsStatuses.WAITING_FOR_PAYMENT:
        const dateDiff = moment.unix(updatedAt.seconds).diff(moment.unix(doneAt.seconds))
        const durr = moment.duration(dateDiff)
        return durr.humanize()
      default:
        return ""
    }
  }

  // const calculateRepairProgress = row => {
  //   const { status, createdAt = null, updatedAt = null, doneAt = null, sentAt = null } = row

  //   const mapValueToDateFormat = v => moment.unix(v.seconds).format(defaultDateShortFormat)

  //   return (
  //     <Progress multi style={{ height: "30px" }}>
  //       <Progress bar color="info" value="15">
  //         {mapValueToDateFormat(createdAt)}
  //       </Progress>
  //       {updatedAt && (
  //         <Progress color="warning" bar striped value="35" className="text-black">
  //           {mapValueToDateFormat(updatedAt)}
  //         </Progress>
  //       )}
  //       {doneAt && (
  //         <Progress color="secondary" bar value="25">
  //           {mapValueToDateFormat(doneAt)}
  //         </Progress>
  //       )}
  //       {status === repairsStatuses.WAITING_FOR_PAYMENT && (
  //         <Progress color="danger" bar value="25">
  //           Płatność
  //         </Progress>
  //       )}
  //       {status === repairsStatuses.UTILIZATION && (
  //         <Progress color="primary" bar striped value="25">
  //           <strong>Utylizacja</strong>
  //         </Progress>
  //       )}
  //       {sentAt && (
  //         <Progress color="success" bar value="25">
  //           {mapValueToDateFormat(sentAt)}
  //         </Progress>
  //       )}
  //     </Progress>
  //   )
  // }

  const mapRepairToAction = useCallback(
    row => {
      const { id, createdAt = null, updatedAt = null, doneAt = null, serial, status } = row
      // const dateCreated = moment.unix(createdAt.seconds).format(repairsCreatedAtDateFormat)
      let timeSpent = status !== repairsStatuses.NEW ? calculateDurations(status, updatedAt, doneAt) : ""
      const rapairStartedDate = moment.unix(createdAt.seconds).format(createdShortDateFormat)
      const linkId = "R" + id.replace("R", "")
      const { productModel = false } = parseSerialNumber(serial)

      const RepairIdLink = () =>
        [repairsStatuses.SENT].includes(status) ? (
          <span>{linkId}</span>
        ) : (
          <span role="button" data-repairid={id} onClick={handleRepairEditCallback}>
            {linkId}
          </span>
        )

      setDropdownList({
        ...dropdownList,
        [id]: false
      })

      return (
        <tr key={id}>
          <td className="repairLink">
            <RepairIdLink />
          </td>
          <td className="repair-serial">
            {serial} <span>{productModel ? `(${productModel.title})` : ""}</span>
          </td>
          <td className="repair-dates">
            {/* <span > */}
            {timeSpent}
            <span>utworzono: {rapairStartedDate}</span>
            {/* </span> */}
          </td>
          <td>
            <Badge color={mapStatusToColor(status)} style={BadgeStyle}>
              {mapStatusToName(status)}
            </Badge>
          </td>
          <td className="row-actions">
            {status === repairsStatuses.NEW && (
              <Button
                color="success"
                size="sm"
                onClick={handleRepairUpdateCallback}
                data-repairid={id}
                data-updatedstatus={repairsStatuses.IN_PROGRESS}
              >
                <FontAwesomeIcon icon={faWrench} /> Rozpocznij
              </Button>
            )}
            {status === repairsStatuses.IN_PROGRESS && (
              <>
                <Button
                  color="primary"
                  size="sm"
                  onClick={handleRepairUpdateCallback}
                  data-repairid={id}
                  data-updatedstatus={repairsStatuses.DONE}
                >
                  <FontAwesomeIcon icon={faWrench} /> Zakończ
                </Button>{" "}
                <Button
                  color="warning"
                  size="sm"
                  onClick={handleRepairUpdateCallback}
                  data-repairid={id}
                  data-updatedstatus={repairsStatuses.UTILIZATION}
                >
                  <FontAwesomeIcon icon={faTrashAlt} /> Utylizuj
                </Button>
              </>
            )}
            {(status === repairsStatuses.DONE || status === repairsStatuses.WAITING_FOR_PAYMENT) && (
              <Button
                color="dark"
                size="sm"
                onClick={handleRepairUpdateCallback}
                data-repairid={id}
                data-updatedstatus={repairsStatuses.SENT}
              >
                <FontAwesomeIcon icon={faPaperPlane} /> Wyślij
              </Button>
            )}
          </td>
        </tr>
      )
    },
    [handleRepairEditCallback, handleRepairUpdateCallback, dropdownList]
  )

  const prepareRepairs = useCallback(
    data => {
      // calculateRepairStats(data)

      return data
        .filter(row => {
          if (showDoneRepairs) {
            return row
          } else {
            if (row.status !== repairsStatuses.SENT && row.status !== repairsStatuses.UTILIZATION) {
              return row
            }
          }
          return null
        })
        .map(mapRepairToAction)
    },
    [mapRepairToAction, showDoneRepairs]
  )

  const fetchRepairs = useCallback(async () => {
    const dispatchRepairs = repairs => dispatch(d(ActionTypes.SET_REPAIRSLIST, repairs))
    await API.fetchRepairs(dispatchRepairs)
  }, [dispatch])

  useEffect(() => {
    const { repairs } = state

    if (repairs) {
      const items = prepareRepairs(repairs)
      setRepairsList(items)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.repairs, showDoneRepairs])

  useEffect(() => {
    fetchRepairs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className="d-flex flex-row justify-content-between align-items-center">
        <RepairStats repairs={state.repairs} />
        <Button className="float-right" onClick={toggleDoneCallback} color="link">
          {showDoneRepairs ? "Ukryj" : "Pokaż"} Wysłane/Utyl.
        </Button>
      </div>
      <Table bordered hover striped>
        <thead>
          <tr>
            <th style={{ width: "10%" }}>Numer naprawy</th>
            <th /*style={{ width: "10%" }}*/>Numer seryjny</th>
            <th>Czas naprawy</th>
            <th>Status</th>
            <th>Akcje</th>
          </tr>
        </thead>
        <tbody>{repairsList}</tbody>
      </Table>
    </>
  )
}

/**/

RepairsList.propTypes = {
  startRepairCallback: PropTypes.func,
  finishRepairCallback: PropTypes.func,
  onFetchCallback: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]).isRequired
}

export default RepairsList
