import React from "react"
import { Button, FormText, FormGroup, Label, Input, InputGroup, /*InputGroupAddon, */ InputGroupText } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMagic, faBarcode } from "@fortawesome/free-solid-svg-icons"
import SerialNumberPopover from "../../components/Warranty/serialNumberModal"
import SerialNumberDecoder from "../../components/SerialNumberDecoder"

export default class SerialNumberField extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isSerialWizardOpen: false
    }

    this.numberRef = React.createRef()
  }

  componentDidMount() {
    // this.numberRef.current.focus();
  }

  render() {
    const { field, form } = this.props
    const { value } = field
    const { errors, setFieldValue } = form

    return (
      <FormGroup>
        <Label for="serialNumber">Numer seryjny</Label>
        <InputGroup>
          <InputGroupText>
            <FontAwesomeIcon icon={faBarcode} />
          </InputGroupText>
          <Input
            type="text"
            maxLength="12"
            placeholder="Wpisz numer seryjny"
            innerRef={this.props.innerRef}
            {...this.props.field}
            valid={!errors["serialNumber"]}
            invalid={!!errors["serialNumber"] || value === ""}
          />
          <Button
            color="primary"
            id="serialNumberWizard"
            onClick={() =>
              this.setState({
                isSerialWizardOpen: true
              })
            }
          >
            <FontAwesomeIcon icon={faMagic} />
          </Button>
          <SerialNumberPopover
            isPopoverOpen={this.state.isSerialWizardOpen}
            popOverToggle={
              () =>
                this.setState({
                  isSerialWizardOpen: !this.state.isSerialWizardOpen
                })
              // setIsSerialWizardOpen(!this.state.isSerialWizardOpen)
            }
            onSerialGeneration={val => setFieldValue("serialNumber", val)}
          />
        </InputGroup>
        <FormText color="muted">
          <SerialNumberDecoder serial={value} />
        </FormText>
      </FormGroup>
    )
  }
}
