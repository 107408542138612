import React, { useState, useCallback, useEffect } from "react"
import PropTypes from "prop-types"
import { Button, Table } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons"
import API from "API"

import useScannerContext from "hooks/useScannerContext"
import { ActionTypes } from "context/scannerProvider"
import { d } from "utils/dispatchHelper"

const ServicePartsList = props => {
  const { updatePartCountCallback } = props
  const [partsList, setPartsList] = useState([])
  const [state, dispatch] = useScannerContext()

  const setServiceParts = useCallback(items => dispatch(d(ActionTypes.SET_SERVICE_PARTS, items)), [dispatch])

  const prepareServiceParts = useCallback(
    data =>
      data.map(({ partCode, partCount, partName }) => {
        return (
          <tr key={partCode}>
            <td>{partCode}</td>
            <td>{partName}</td>
            <td>{partCount}</td>
            <td>
              <Button
                color="primary"
                size="sm"
                onClick={updatePartCountCallback}
                data-partcode={partCode}
                data-partname={partName}
                data-partcount={partCount}
              >
                <FontAwesomeIcon icon={faPlusCircle} /> Dodaj do stanu
              </Button>
            </td>
          </tr>
        )
      }),
    [updatePartCountCallback]
  )

  const fetchServiceParts = useCallback(async () => {
    await API.fetchServiceParts(setServiceParts)
  }, [setServiceParts])

  useEffect(() => {
    fetchServiceParts()
  }, [fetchServiceParts])

  useEffect(() => {
    if (state.serviceParts.length) {
      const items = prepareServiceParts(state.serviceParts)
      setPartsList(items)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.serviceParts])

  return (
    <Table bordered hover striped>
      <thead>
        <tr>
          <th>Kod</th>
          <th>Nazwa</th>
          <th>Ilość</th>
          <th>Akcje</th>
        </tr>
      </thead>
      <tbody>{partsList}</tbody>
    </Table>
  )
}

ServicePartsList.propTypes = {
  updatePartCountCallback: PropTypes.func.isRequired
}

export default ServicePartsList
