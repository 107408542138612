import React from "react"
import { Form, FormGroup, Label, Col, Input, Button } from "reactstrap"
import { withFormik } from "formik"
import * as yup from "yup"
import { productModels, productColors, productBatch } from "../constants/product"

const initialValues = {
  productModel: productModels[0].serialPart,
  productColor: productColors[0].serialPart.toString(),
  productBatch: productBatch[0].toString(),
  serialsCount: 0,
  serialsStartingNumber: 1
}

const validationSchema = yup.object().shape({
  productModel: yup.string().required(),
  productColor: yup.string().required(),
  productBatch: yup.string().required(),
  serialsCount: yup.number().required(),
  serialsStartingNumber: yup.number().required()
})

const batchSerialsForm = props => {
  const { values, errors, handleChange, handleSubmit, handleBlur, isSubmitting } = props

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup row>
        <Label for="productModel" sm={2}>
          Model
        </Label>
        <Col sm={10}>
          {productModels.map(({ serialPart, title }) => (
            <FormGroup check key={serialPart} inline>
              <Label>
                <Input
                  type="radio"
                  name="productModel"
                  id={serialPart}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={serialPart}
                  autoComplete="off"
                  defaultChecked={serialPart === productModels[0].serialPart}
                />{" "}
                {title}
              </Label>
            </FormGroup>
          ))}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="productColor" sm={2}>
          Kolor
        </Label>
        <Col sm={10}>
          {productColors.map(({ name, serialPart }) => (
            <FormGroup check key={serialPart} inline>
              <Label>
                <Input
                  type="radio"
                  name="productColor"
                  id={serialPart}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={serialPart}
                  autoComplete="off"
                  defaultChecked={serialPart === productColors[0].serialPart}
                />{" "}
                {name}
              </Label>
            </FormGroup>
          ))}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="productBatch" sm={2}>
          Nr partii
        </Label>
        <Col sm={10}>
          {productBatch.map(value => (
            <FormGroup check key={value} inline>
              <Label>
                <Input
                  type="radio"
                  name="productBatch"
                  id={value}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={value}
                  autoComplete="off"
                  defaultChecked={value === 0}
                />
                {` ${value}`}
              </Label>
            </FormGroup>
          ))}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="serialsCount" sm={2}>
          Ilość numerów seryjnych
        </Label>
        <Col sm={10}>
          <FormGroup check inline>
            <Label>
              <Input
                type="text"
                name="serialsCount"
                placeholder="Ilość numerów seryjnych"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.serialsCount}
                autoComplete="off"
                maxLength="7"
                valid={errors["serialsCount"] === undefined}
                invalid={errors["serialsCount"] !== undefined}
              />
            </Label>
          </FormGroup>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="serialsStartingNumber" sm={2}>
          Początkowy numer
        </Label>
        <Col sm={10}>
          <FormGroup check inline>
            <Label>
              <Input
                type="text"
                name="serialsStartingNumber"
                placeholder="Początkowy numer"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.serialsStartingNumber}
                autoComplete="off"
                maxLength="7"
                valid={errors["serialsStartingNumber"] === undefined}
                invalid={errors["serialsStartingNumber"] !== undefined}
              />
            </Label>
          </FormGroup>
        </Col>
      </FormGroup>
      <Button type="submit" color="primary" disabled={isSubmitting}>
        Zapisz
      </Button>
    </Form>
  )
}

export default withFormik({
  mapPropsToValues: props => initialValues,
  validationSchema: validationSchema,
  enableReinitialize: true,
  handleSubmit: (values, formikBag) => {
    const { props, setSubmitting } = formikBag
    props.saveForm(values)
    setSubmitting(false)
  },
  displayName: "SerialNumbersForm"
})(batchSerialsForm)
