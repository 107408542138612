import { useContext } from "react"
import { AuthorContext } from "context/authorProvider"

const useAuthorContext = () => {
  const [author, setAuthor] = useContext(AuthorContext)

  const updateAuthor = name => setAuthor(name)

  return [author, updateAuthor]
}

export default useAuthorContext
