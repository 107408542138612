import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { printerOnline, printerOffline } from "../store/actions/printer"

export default function usePrinterStatus() {
  const [isAvailable, setIsAvailable] = useState(false)
  const PRINTER_HOST = process.env.REACT_APP_PRINTER_HOST || "http://192.168.1.55:8888"

  const dispatch = useDispatch()

  useEffect(() => {
    async function getPrinterInfo() {
      let status = false
      try {
        let response = await fetch(`${PRINTER_HOST}/status`, { referrerPolicy: "no-referrer" })
        status = response.status === 200
      } catch (err) {
        status = false
      }
      return status
    }

    getPrinterInfo().then(status => {
      dispatch(status ? printerOnline() : printerOffline())
      setIsAvailable(status)
    })

    // eslint-disable-next-line
  }, [])

  return isAvailable
}
