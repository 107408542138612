import React from "react"
import SerialNumbersList from "./list"
// import WarrantyModal from "./modal";
// import SearchForm from "./SearchForm";
// import ServiceOrderNavigation from "./ServiceOrderNavigation";

const SerialNumbersV2 = props => (
  <div className="content-section">
    <h3>Lista numerów seryjnych</h3>
    {/* <ServiceOrderNavigation /> */}
    {/* <SearchForm /> */}
    <SerialNumbersList />
    {/* <WarrantyModal /> */}
  </div>
)

export default SerialNumbersV2
