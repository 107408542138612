import { useContext } from "react"
import { ScannerContext } from "context/scannerProvider"

const useScannerContext = () => {
  const [store, dispatch] = useContext(ScannerContext)

  return [store, dispatch]
}

export default useScannerContext
