import React from "react"
import SerialNumbersNavigation from "./navigation"
import SerialNumberGenerator from "../../../components/SerialNumberGenerator"
import SerialNumbersList from "./list"

const SerialNumbers = props => (
  <div className="content-section">
    <h3>Numery seryjne</h3>
    <SerialNumbersNavigation />
    <SerialNumberGenerator />
    <SerialNumbersList />
  </div>
)

export default SerialNumbers
