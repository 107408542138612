import React from "react"
import { FormGroup, Label, Input } from "reactstrap"
import { productWarranty } from "../../constants/product"

const WarrantyTypeField = props => {
  const { values, handleBlur, setFieldValue } = props

  const onChangeRadio = e => {
    setFieldValue("warrantyLength", parseInt(e.target.value))
  }

  return (
    <FormGroup tag="fieldset">
      <Label>Gwarancja </Label>
      {productWarranty.map(({ title, count }) => (
        <FormGroup check key={count}>
          <Label>
            <Input
              name="warrantyLength"
              autoComplete="off"
              type="radio"
              value={count}
              onChange={onChangeRadio}
              onBlur={handleBlur}
              checked={values.warrantyLength === count}
            />
            {title}
          </Label>
        </FormGroup>
      ))}
    </FormGroup>
  )
}

export default WarrantyTypeField
