import React from "react"
import { connect } from "react-redux"
import { Button, Form, FormGroup } from "reactstrap"
import { withFormik, Field } from "formik"
import { warrantyValidationSchema } from "./validators"
import SerialNumberField from "./fields/SerialNumberField"
import WarrantyTypeField from "./fields/WarrantyTypeField"
import PurchaseDateField from "./fields/PurchaseDateField"
import * as actions from "../store/actions/serialNumbersV2"

const SerialNumberForm = props => {
  const { item, serialNumber, fetchSerialNumber, isValid, isSubmitting, dirty, handleSubmit } = props

  React.useEffect(() => {
    fetchSerialNumber(serialNumber)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return item !== null ? (
    <Form>
      <FormGroup>
        <Field name="serialNumber" render={props => <SerialNumberField {...props} />} />
      </FormGroup>
      <FormGroup>
        <WarrantyTypeField {...props} />
      </FormGroup>
      <FormGroup>
        <Field name="purchaseDate" component={PurchaseDateField} />
      </FormGroup>{" "}
      <Button type="submit" color="primary" disabled={!isValid || isSubmitting || !dirty} onClick={handleSubmit}>
        Zapisz
      </Button>{" "}
    </Form>
  ) : (
    <div />
  )
}

// export default SerialNumberForm;

const EnchancedSerialNumberForm = withFormik({
  mapPropsToValues: props => {
    const { item } = props

    return item !== null
      ? {
          serialNumber: item.id,
          warrantyLength: item.warranty.reduce((acc, cur) => cur.warrantyLength, null),
          purchaseDate: item.warranty.reduce((acc, cur) => new Date(cur.purchaseDate.seconds * 1000), new Date())
        }
      : {
          serialNumber: "",
          warrantyLength: null,
          purchaseDate: ""
        }
  },
  validationSchema: warrantyValidationSchema,
  isInitialValid: true,
  handleSubmit: async (values, { props, setSubmitting }) => {
    setSubmitting(true)

    // await props.updateWarranty(values);
    setSubmitting(false)
  },
  enableReinitialize: true,
  displayName: "SerialNumberForm"
})(SerialNumberForm)

const mapStateToProps = state => ({
  item: state.serialNumbersV2.item
})

const mapDispatchToProps = dispatch => ({
  // updateWarranty: data => dispatch(actions.updateWarranty(data)),
  updateWarranty: data => console.log(data),
  fetchSerialNumber: serialNumberId => dispatch(actions.getItem(serialNumberId))
})

export default connect(mapStateToProps, mapDispatchToProps)(EnchancedSerialNumberForm)
