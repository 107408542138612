import React, { useEffect } from "react"
import { connect } from "react-redux"
import LoginForm from "./forms/LoginForm"
import AppContainer from "./components/AppContainer"
import { handleUserLogin } from "./store/actions/appState"
import { auth } from "./firebase"

const AppWithLogin = props => {
  useEffect(() => {
    auth.onAuthStateChanged(props.handleUser)
  }, []) // eslint-disable-line

  return props.userLoggedIn ? <AppContainer /> : <LoginForm />
}

const mapStateToProps = state => ({
  userLoggedIn: state.appState.loggedIn
})

const mapDispatchToProps = dispatch => ({
  handleUser: user => dispatch(handleUserLogin(user))
})

export default connect(mapStateToProps, mapDispatchToProps)(AppWithLogin)
