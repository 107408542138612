import API from "../../API"
import * as actions from "./actionTypes"
import * as AppState from "./appState"
// import { batch } from "react-redux";

// export const openModal = () => ({
//   type: warrantyActions.WARRANTY_MODAL_OPEN
// });

export const closeModal = () => ({
  type: actions.MODAL_CLOSE
})

// export const toggleModal = () => ({
//   type: warrantyActions.WARRANTY_MODAL_TOGGLE
// });

// export const toggleEditModal = () => ({
//   type: warrantyActions.WARRANTY_MODAL_EDIT_TOGGLE
// });

// export const addWarranty = values => dispatch => {
//   API.saveWarranty(values);
// };

// export const itemsFetched = items => ({
//   type: actions.GET_RETURNS,
//   payload: items
// });

export const editWarranty = warrantyData => ({
  type: actions.EDIT_WARRANTY_ITEM,
  payload: warrantyData
})

// export const warrantyToEdit = item => async dispatch => {
//   await batch(() => {
//     dispatch({
//       type: actions.EDIT_WARRANTY_ITEM,
//       payload: item
//     });
//     dispatch({
//       type: actions.WARRANTY_MODAL_OPEN
//     });
//   });
// };

export const fetchItems = () => async dispatch =>
  dispatch(AppState.loading())
    .then(() =>
      dispatch({
        type: actions.GET_RETURNS,
        meta: {
          collection: "returns"
        }
      })
    )
    .then(() => dispatch(AppState.done()))

// export const findItem = serialNumber => async dispatch => {
//   dispatch(AppState.loading());
//   try {
//     const items = await API.findWarranties(serialNumber);
//     batch(() => {
//       dispatch(itemsFetched(items));
//       dispatch(AppState.done());
//     });
//   } catch (err) {
//     console.log(err);
//   }
// };

// export const editWarranty = serialNumber => async dispatch => {
//   // dispatch(AppState.loading());
//   try {
//     const items = await API.findWarranties(serialNumber);
//     dispatch(warrantyToEdit(items.pop()));
//     // dispatch(AppState.done());
//   } catch (err) {
//     console.log(err);
//   }
// };

export const saveItem = data => async dispatch => {
  dispatch(AppState.loading())
  try {
    await API.saveWarranty(data)
    // dispatch(fetchWarranties());
    dispatch(closeModal())
  } catch (err) {
    console.log(err)
  }
  dispatch(AppState.done())
}

export const changeItemsPerPage = itemsPerPage => ({
  type: actions.WARRANTY_CHANGE_ITEMS_PER_PAGE,
  payload: parseInt(itemsPerPage)
})

export const fetchByCursor = cursor => ({
  type: actions.WARRANTY_FETCH_BY_CURSOR,
  payload: cursor
})
