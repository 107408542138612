import React from "react"
import WarrantyList from "./list"
import WarrantyModal from "./modal"
// import WarrantyEditModal from "./editModal";
import SearchForm from "./SearchForm"
import WarrantyNavigation from "./WarrantyNavigation"

const Warranty = props => (
  <div className="content-section">
    <h3>Lista gwarancji</h3>
    <WarrantyNavigation />
    <SearchForm />
    <WarrantyList />
    <WarrantyModal />
    {/* <WarrantyEditModal /> */}
  </div>
)

export default Warranty
