import { SERVICE_ORDERS_UPDATE, SERVICE_ORDERS_VIEW_ACTIVE, SERVICE_ORDERS_VIEW_ARCHIVES } from "../actions/actionTypes"

const initialState = {
  orders: [],
  viewActiveOrders: true
}

const serviceOrders = (state = initialState, { type, payload }) => {
  switch (type) {
    case SERVICE_ORDERS_UPDATE:
      return {
        ...state,
        orders: payload
      }

    case SERVICE_ORDERS_VIEW_ARCHIVES:
      return {
        ...state,
        viewActiveOrders: false
      }

    case SERVICE_ORDERS_VIEW_ACTIVE:
      return {
        ...state,
        viewActiveOrders: true
      }

    default:
      return state
  }
}

export default serviceOrders
