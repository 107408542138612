import React from "react"
import parseSerialNumber from "utils/serialNumberParser"

const SerialNumberDecoder = props => {
  const { serial = "" } = props

  const {
    productModel = { title: "Nieznany" },
    productColor = { name: "Nieznany" },
    productBatch = "Nieznany",
    productSerial = "Nieznany"
  } = parseSerialNumber(serial)

  if (serial.length < 12) return null

  return (
    <span>
      {`Model: ${productModel.title}, Kolor: ${productColor.name}, Nr partii: ${productBatch}, Nr seryjny: ${productSerial}`}
    </span>
  )
}

export default SerialNumberDecoder
