import React from "react"
import { Table, Button, Input } from "reactstrap"
import { connect } from "react-redux"
import { fetchItems } from "../../../store/actions/serialNumbers"
import { openModal } from "../../../store/actions/modal"
import { defaultItemsPerPage } from "../../../constants/ui"
import { mapNumberToColor, mapNumberToProductModel, batchSerialsFromItem } from "utils/serialNumberParser"
import ContentModal from "../../../components/Modal"

import PrintButton from "../../../components/PrintButton"

const SerialNumbersList = props => {
  React.useEffect(() => {
    props.fetchItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadModalContent = item => {
    const generatedNumbers = batchSerialsFromItem(item)
    props.openModalWithSerials(generateSerialNumbersList(generatedNumbers))
  }

  const ListActionItem = ({ id, item }) => {
    return (
      <React.Fragment>
        <Button size="sm" color="primary" onClick={() => loadModalContent(item)}>
          Zobacz
        </Button>{" "}
        <PrintButton barcode={batchSerialsFromItem(item)} printBatch />
      </React.Fragment>
    )
  }

  const generateSerialNumbersList = numbers => (
    <React.Fragment>
      <Input type="textarea" defaultValue={numbers.join("\n")} rows={10} />
      <div>
        <small className="text-muted">Ilość numerów seryjnych: {numbers.length}</small>
      </div>
    </React.Fragment>
  )

  const mapDataToRow = data => {
    const { id, productModel, productColor, productBatch, startingNumber, count } = data

    return (
      <tr key={`${productModel}${productColor}${productBatch}${startingNumber}`}>
        <td>{mapNumberToProductModel(productModel)}</td>
        <td>{`${productColor} - ${mapNumberToColor(productColor)}`}</td>
        <td>{productBatch}</td>
        <td>{startingNumber}</td>
        <td>{count}</td>
        <td>
          <ListActionItem id={id} item={data} />
        </td>
      </tr>
    )
  }

  return (
    <React.Fragment>
      {props.loading ? (
        <div className="loading">Loading data...</div>
      ) : (
        <React.Fragment>
          <Table bordered hover striped>
            <thead>
              <tr>
                <th>Model</th>
                <th>Kolor</th>
                <th>Nr partii</th>
                <th>Początkowy numer</th>
                <th>Ilość</th>
                <th>Akcje</th>
              </tr>
            </thead>
            <tbody>
              {props.items.length > 0 ? (
                props.items.map(mapDataToRow)
              ) : (
                <tr>
                  <td colSpan="5">Brak danych</td>
                </tr>
              )}
            </tbody>
          </Table>
          <ContentModal />
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  items: state.serialNumbers.items,
  itemsPerPage: defaultItemsPerPage,
  loading: state.appState.loading
})

const mapDispatchToProps = dispatch => ({
  openModalWithSerials: content => dispatch(openModal(content)),
  fetchItems: () => dispatch(fetchItems())
})

export default connect(mapStateToProps, mapDispatchToProps)(SerialNumbersList)
