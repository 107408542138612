import { useState, useEffect } from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { mapStatusToName } from "constants/repairs"

const RepairInfoModal = ({ repairData }) => {
  const [modal, setModal] = useState(false)

  useEffect(() => {
    if (repairData) {
      toggle()
    }
  }, [repairData])

  const toggle = () => setModal(!modal)

  return (
    <Modal isOpen={modal} toggle={toggle} fade={false}>
      <ModalHeader toggle={toggle}>Szczegóły naprawy</ModalHeader>
      <ModalBody>
        {repairData && (
          <>
            <p>Numer naprawy: {repairData.id}</p>
            <p>Numer seryjny: {repairData.serial}</p>
            <p>Status naprawy: {mapStatusToName(repairData.status)}</p>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={toggle}>
          Zamknij
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default RepairInfoModal
