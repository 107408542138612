import React, { useCallback } from "react"
import { Input, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faMinus, faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons"

import BoxIcon from "components/BoxIcon"
import useAuthorContext from "hooks/useAuthorContext"
import useScannerContext from "hooks/useScannerContext"
import { ActionTypes } from "context/scannerProvider"
import { d } from "utils/dispatchHelper"
import "./styles.scss"

const ScannerPartWindow = props => {
  const { handlePartsSave } = props

  const [author] = useAuthorContext()
  const [state, dispatch] = useScannerContext()

  const { isScanOpen, scannedPartCount, partName, partCode, partCount } = state

  //  @todo: acc check for max scanned items
  const handleOnInputChange = e => {
    const fieldValue = e.target.value
    const isEmpty = fieldValue === "" || isNaN(fieldValue)
    handleCountChange(isEmpty ? "" : parseInt(fieldValue))
  }

  const handleCountChange = useCallback(
    count => {
      let filteredCount = count > 0 ? count : 0

      //  dont add more parts than stock
      if (filteredCount <= partCount) {
        dispatch(d(ActionTypes.SCAN_PART, filteredCount))
      }
    },
    [dispatch, partCount]
  )

  const toggle = useCallback(() => dispatch(d(ActionTypes.SCANNER_WINDOW_TOGGLE)), [dispatch])

  return (
    <Modal isOpen={isScanOpen} fade={false} centered={true} size="lg" className="scannerWindow">
      <ModalHeader toggle={toggle}>Zdejmij części z magazynu</ModalHeader>
      <ModalBody className="scanner-body">
        <div className="part-title">
          <h4>{partName}</h4>
          <em>{partCode}</em>
        </div>
        <div className="part-counter">
          <h4>Ilość:</h4>
          <div className="controls">
            <Button color="primary" onClick={() => handleCountChange(scannedPartCount + 1)}>
              <FontAwesomeIcon icon={faPlus} />
            </Button>
            <Input type="text" className="partCount" value={scannedPartCount} onChange={handleOnInputChange} />
            <Button color="primary" onClick={() => handleCountChange(scannedPartCount - 1)}>
              <FontAwesomeIcon icon={faMinus} />
            </Button>
          </div>
        </div>
        <div className="part-remaining">
          <BoxIcon /> Ilość w magazynie: <strong>{partCount}</strong> szt.
        </div>
      </ModalBody>
      <ModalFooter className="scanner-footer">
        <span>
          Skanujesz jako: <strong>{author}</strong>
        </span>
        <Button color="info" onClick={() => handlePartsSave("UPDATE")}>
          <FontAwesomeIcon icon={faPlusCircle} /> Dodaj do stanu
        </Button>{" "}
        <Button color="primary" onClick={handlePartsSave}>
          <FontAwesomeIcon icon={faMinusCircle} /> Zdejmij ze stanu
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ScannerPartWindow
