import React from "react"
import { connect } from "react-redux"
import { Nav, NavItem, NavLink, Button } from "reactstrap"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { openModal } from "../../store/actions/warranty"

const NavContainer = styled.div`
  margin: 10px 0;
`

const ReturnsNavigation = props => {
  return (
    <NavContainer>
      <Nav>
        <NavItem>
          <NavLink color="success" onClick={props.openModal} tag={Button}>
            <FontAwesomeIcon icon={faPlus} /> Dodaj zwrot
          </NavLink>
        </NavItem>
      </Nav>
    </NavContainer>
  )
}

const mapDispatchToProps = dispatch => ({
  openModal: () => dispatch(openModal())
})

export default connect(null, mapDispatchToProps)(ReturnsNavigation)
