import React, { useState, useCallback, useEffect } from "react"
import moment from "moment"

import { servicePartsHistoryFormat } from "constants/serviceParts"
import API from "../../API"
import BoxIcon from "components/BoxIcon"

import useScannerContext from "hooks/useScannerContext"

import { ActionTypes } from "context/scannerProvider"
import { d } from "utils/dispatchHelper"

import "./style.scss"

const ServicePartsHistory = () => {
  const [partsHistory, setPartsHistory] = useState([])
  const [state, dispatch] = useScannerContext()

  const setServicePartsHistory = useCallback(
    items => {
      dispatch(d(ActionTypes.SET_SERVICE_PARTS_HISTORY, items))
    },
    [dispatch]
  )

  const mapHistoryTypeToOperation = historyType => {
    switch (historyType) {
      case "ADD":
        return ["dodano ", "add"]
      case "REMOVE":
        return ["zdjęto ", "del"]
      case "UPDATE":
        return ["zaktualizowano ", "up"]
      default:
        return ["nieznana akcja", ""]
    }
  }

  const prepareServicePartsHistory = useCallback(
    data =>
      data.map((row, index) => {
        const { createdAt, createdBy, historyType, partName, partCount, previousCount = 0 } = row
        const historyDate = moment.unix(createdAt).format(servicePartsHistoryFormat)
        const [operation, actionClass] = mapHistoryTypeToOperation(historyType)
        const countDiff = partCount - previousCount

        return (
          <span key={index}>
            <small>{historyDate}</small> {operation} <strong>{partName}</strong>
            <em className={`op op-${actionClass}`}>
              <strong>({countDiff})</strong>
            </em>
            <em>
              {" "}
              <BoxIcon /> {previousCount} &rarr; {partCount} &minus; {createdBy}
            </em>
          </span>
        )
      }),
    []
  )

  const fetchServicePartsHistory = useCallback(async () => {
    await API.fetchServicePartsHistory(setServicePartsHistory)
    // const items = prepareServicePartsHistory(data)
    // setPartsHistory(items)
  }, [setServicePartsHistory])

  useEffect(() => {
    fetchServicePartsHistory()
  }, [fetchServicePartsHistory])

  useEffect(() => {
    if (state.partsHistory.length) {
      setPartsHistory(prepareServicePartsHistory(state.partsHistory))
    }
  }, [prepareServicePartsHistory, state.partsHistory])

  return <div className="partsHistoryContent">{partsHistory}</div>
}

export default ServicePartsHistory
