import React, { useState } from "react"
import { Button, FormText, FormFeedback, FormGroup, Label, Input, InputGroup, InputGroupText } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMagic, faBarcode } from "@fortawesome/free-solid-svg-icons"
import { Controller } from "react-hook-form"

import SerialNumberPopover from "components/Warranty/serialNumberModal"
import SerialNumberDecoder from "components/SerialNumberDecoder"

const SerialNumberFieldWithWizard = props => {
  const [numberPopover, setNumberPopover] = useState(false)
  const { name, control, setValue, errors, serialRef } = props

  const togglePopover = () => setNumberPopover(!numberPopover)
  const handleSerialNumberGeneratorValue = val => setValue(name, val)

  return (
    <FormGroup>
      <Label for="serialNumber">Numer seryjny</Label>
      <InputGroup>
        <InputGroupText>
          <FontAwesomeIcon icon={faBarcode} />
        </InputGroupText>
        <Controller
          name={name}
          control={control}
          rules={{ maxLength: 12 }}
          render={({ field }) => (
            <>
              <Input
                type="text"
                placeholder="Wpisz numer seryjny"
                {...field}
                maxLength={12}
                value={field.value}
                valid={field.value.length === 12}
                invalid={errors.serialNumber !== undefined}
                aria-invalid={field.value.length !== 12}
                innerRef={serialRef}
              />
            </>
          )}
        ></Controller>
        <Button color="primary" id="serialNumberWizard" onClick={togglePopover}>
          <FontAwesomeIcon icon={faMagic} />
        </Button>
        <SerialNumberPopover
          isPopoverOpen={numberPopover}
          popOverToggle={togglePopover}
          onSerialGeneration={handleSerialNumberGeneratorValue}
        />
        {errors.serialNumber && <FormFeedback>Niepoprawny numer seryjny</FormFeedback>}
      </InputGroup>
      <FormText color="muted">
        <Controller
          control={control}
          name={name}
          render={({ field }) => <SerialNumberDecoder serial={field.value} />}
        />
      </FormText>
    </FormGroup>
  )
}

export default SerialNumberFieldWithWizard
