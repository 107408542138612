import { productModels, productColors } from "../constants/product"

export const mapNumberToColor = colorNumber =>
  productColors.filter(el => el.value === colorNumber).reduce((acc, cur) => (acc += cur.name), "")

export const mapNumberToProductModel = productNumber =>
  productModels.filter(el => el.serialPart === productNumber).reduce((acc, cur) => (acc += cur.title), "")

const parseSerialNumber = serial => {
  if (serial.length < 12) return false
  const matches = serial.match(/(\d{3})(\d{1})(\d{1})(\d{7})/)

  if (matches === null) return false

  const [, model, productBatch, color, productSerial] = matches

  return {
    productModel: productModels.filter(item => item.serialPart.toString() === model).pop(),
    productColor: productColors.filter(item => item.serialPart.toString() === color).pop(),
    productBatch,
    productSerial
  }
}

export const createSerialNumber = (
  { productModel, productColor, productBatch, serialNumber },
  withCheckDigit = true
) => {
  const paddedNumber = ("0000000" + serialNumber).substring(serialNumber.length)
  const newSerial = `${productModel}${productBatch}${productColor}${paddedNumber}`

  return withCheckDigit ? `${newSerial}${calculateSerialCheckDigit(newSerial)}` : newSerial
}

export const calculateSerialCheckDigit = serialNumber => {
  if (serialNumber.length !== 12 && serialNumber.length !== 13) {
    return -1
  }

  let sum = 0

  for (let i = 11; i >= 0; i--) {
    const digit = parseInt(serialNumber[i])
    const waga = (i & 0x01) === 1 ? digit * 3 : digit * 1
    sum += waga
  }
  const mod = sum % 10
  return mod === 0 ? 0 : 10 - mod
}

export const batchSerialsFromItem = item => {
  let serials = []
  const { productModel, productColor, productBatch, startingNumber, count } = item
  const lastSerialNumber = startingNumber + count
  for (let x = startingNumber; x < lastSerialNumber; x++) {
    serials.push(
      createSerialNumber({
        productModel,
        productColor,
        productBatch,
        serialNumber: x.toString()
      })
    )
  }
  return serials
}

export default parseSerialNumber
