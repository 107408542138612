/* eslint-disable no-console */
import firebase, { db } from "../../firebase"
import moment from "moment"
import { defaultItemsPerPage } from "../../constants/ui"

// import * as appActions from "../actions/appState";

const getDocument = doc => ({ id: doc.id, cached: doc.cached, ...doc.data() })
// const mapSnapshotToDocs = snapshot =>
//   snapshot.docs.map(doc => getDocument(doc));

const apiMiddleware = store => next => async action => {
  if (!action.meta) return next(action)

  const {
    collection,
    method = "fetch",
    orderBy = "createdAt",
    // startAt = {},
    limit = defaultItemsPerPage
  } = action.meta

  var items = []
  var newAction = null
  // var itemsCount = 0

  const dispatchNewAction = content => {
    newAction = {
      ...action,
      payload: content
    }
    delete newAction.meta
    store.dispatch(newAction)
  }

  switch (method) {
    case "fetch":
      items = []
      // itemsCount = 0
      await db
        .collection(collection)
        .orderBy(orderBy, "desc")
        .limit(limit)
        .onSnapshot(snapshot => {
          items = []
          snapshot.forEach(doc => {
            items.push(getDocument(doc))
            // itemsCount++
          })
          // console.log("Fetched snapshots", itemsCount)
          dispatchNewAction(items)
        })
      break

    case "find":
      await db
        .collection(collection)
        .where(`${action.meta.searchKey}`, "==", `${action.meta.searchTerm}`)
        .orderBy(orderBy, "desc")
        .get()
        .then(snapshot => snapshot.forEach(doc => items.push(getDocument(doc))))
        .catch(err => console.log(err))
      dispatchNewAction(items)
      break

    case "paginate":
      const direction = action.meta.direction === "next" ? "<=" : ">"
      await db
        .collection(collection)
        .orderBy("purchaseDate", "desc")
        .where("purchaseDate", direction, action.meta.cursor)
        .limit(limit)
        .onSnapshot(snapshot => {
          snapshot.forEach(doc => items.push(getDocument(doc)))
          dispatchNewAction(items)
        })
      break

    case "findOne":
      let item = null
      await db
        .collection(collection)
        .doc(action.meta.docId)
        // .where(`${action.meta.entryId}`, "==", `${action.meta.searchTerm}`)
        // .orderBy(orderBy, "desc")
        .get()
        .then(snapshot => {
          item = getDocument(snapshot)
        })
        .catch(err => console.log(err))
      dispatchNewAction(item)
      break

    case "saveItem":
      const { purchaseDate, serialNumber, warrantyLength } = action.payload
      const purchaseDateTimestamp = firebase.firestore.Timestamp.fromDate(moment(purchaseDate).toDate())
      const warrantyLengthInt = parseInt(warrantyLength)
      const timeNow = firebase.firestore.Timestamp.now()

      try {
        //  save data to warranties
        await db.collection(collection).add({
          purchaseDate: purchaseDateTimestamp,
          serialNumber,
          warrantyLength: warrantyLengthInt,
          createdAt: timeNow
        })
        await db
          .collection("serialNumbers")
          .doc(serialNumber)
          .set({
            createdAt: timeNow,
            warranty: [
              {
                purchaseDate: purchaseDateTimestamp,
                warrantyLength: warrantyLengthInt
              }
            ]
          })
        dispatchNewAction(null)
      } catch (err) {
        console.log(err)
      }
      break

    case "save":
      const { productBatch, productColor, productModel, serialsCount, serialsStartingNumber } = action.payload
      await db
        .collection(collection)
        .add({
          count: parseInt(serialsCount),
          createdAt: firebase.firestore.Timestamp.now(),
          productBatch: parseInt(productBatch),
          productColor: parseInt(productColor),
          productModel: parseInt(productModel),
          startingNumber: parseInt(serialsStartingNumber)
        })
        .then(doc => dispatchNewAction(doc))
        .catch(err => console.log(err))
      break

    case "single":
      await db
        .collection(collection)
        .where(`${action.meta.searchKey}`, "==", `${action.meta.searchTerm}`)
        .get()
        .then(doc => {
          items = getDocument(doc)
        })
        .catch(err => console.log(err))
      dispatchNewAction(items)
      break

    default:
      return next(action)
  }
}

export default apiMiddleware
