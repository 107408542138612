const PRINTER_HOST = process.env.REACT_APP_PRINTER_HOST

const printSerialNumber = async (serialNumber, count = 1) => {
  const response = await fetch(`${PRINTER_HOST}/print/${serialNumber}/${count}`, {
    method: "POST"
  })

  if (response.status !== 200) {
    throw new Error("Bad parameters for the print")
  }
}
const printBatchSerialNumbers = async (serialNumbers, count = 1) => {
  let resp

  resp = await fetch(`${PRINTER_HOST}/printBatch/${count}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ serialNumbers: serialNumbers })
  })

  if (resp.status !== 200) {
    throw new Error("Bad parameters for the print")
  }
}

export { printSerialNumber, printBatchSerialNumbers }
