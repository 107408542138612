import React from "react"
import {
  Button,
  Form,
  FormGroup,
  Input,
  InputGroup,
  // InputGroupAddon,
  // Pagination,
  // PaginationItem,
  // PaginationLink,
  Col,
  Row
} from "reactstrap"
import { connect } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import {
  // findBySerialNumber,
  fetchWarranties,
  findWarranty,
  // findBySerialNumber,
  warrantiesFetched
  // paginateResults
} from "../../store/actions/warranty"

import "./style.css"

const SearchForm = props => {
  const [searchField, setSearchField] = React.useState("")
  const { findSerial /*, items, itemsPerPage, firstItem, paginate*/ } = props

  const resetSearch = () => props.clearSearch()

  const handleTextChange = ev => {
    const val = ev.target.value
    if (searchField.length > 0 && val === "") {
      resetSearch()
    }
    setSearchField(val)
  }

  const handleFindSerial = ev => {
    ev.preventDefault()
    return searchField.length > 0 ? findSerial(searchField) : resetSearch()
  }

  return (
    <Row>
      <Col sm="4" className="mr-auto">
        <Form size="sm" onSubmit={handleFindSerial}>
          <FormGroup>
            <InputGroup>
              <Button color="info" onClick={handleFindSerial}>
                <FontAwesomeIcon icon={faSearch} />
              </Button>
              <Input
                type="search"
                name="warrantySearch"
                id="warrantySearch"
                onChange={handleTextChange}
                placeholder="Szukaj numeru seryjnego"
                size="12"
                maxLength={12}
                autoComplete="off"
              />
            </InputGroup>
          </FormGroup>
        </Form>
      </Col>
    </Row>
  )
}

const mapStateToProps = state => ({
  firstItem: state.warranty.firstItem,
  items: state.warranty.items,
  itemsPerPage: state.warranty.itemsPerPage
})

const mapDispatchToProps = dispatch => ({
  findSerial: serial => dispatch(findWarranty(serial)),
  clearSearch: () => dispatch(fetchWarranties()),
  warrantiesFetched: items => dispatch(warrantiesFetched(items))
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchForm)
