import { Button } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"

const LoadingButton = props => {
  const { isLoading, ...otherProps } = props
  return (
    <Button {...otherProps}>
      {isLoading === true && <FontAwesomeIcon icon={faSpinner} spin />} {props.children}
    </Button>
  )
}

export default LoadingButton
