export const APP_LOADING = "APP_LOADING"
export const APP_LOADING_DONE = "APP_LOADING_DONE"
export const APP_LOADING_ERROR = "APP_LOADING_DONE"

export const HANDLE_USER_STATE = "HANDLE_USER_STATE"

export const GET_WARRANTY_ITEMS_PENDING = "GET_WARRANTY_ITEMS_PENDING"
export const GET_WARRANTY_ITEMS_SUCCESS = "GET_WARRANTY_ITEMS_SUCCESS"

export const EDIT_WARRANTY_ITEM = "EDIT_WARRANTY_ITEM"

export const SERVICE_ORDERS_UPDATE = "SERVICE_ORDERS_UPDATE"
export const SERVICE_ORDERS_VIEW_ACTIVE = "SERVICE_ORDERS_VIEW_ACTIVE"
export const SERVICE_ORDERS_VIEW_ARCHIVES = "SERVICE_ORDERS_VIEW_ARCHIVES"

export const WARRANTY_MODAL_OPEN = "WARRANTY_MODAL_OPEN"
export const WARRANTY_MODAL_CLOSE = "WARRANTY_MODAL_CLOSE"
export const WARRANTY_MODAL_TOGGLE = "WARRANTY_MODAL_TOGGLE"
export const WARRANTY_MODAL_EDIT_TOGGLE = "WARRANTY_MODAL_EDIT_TOGGLE"

export const WARRANTY_CHANGE_ITEMS_PER_PAGE = "WARRANTY_CHANGE_ITEMS_PER_PAGE"
export const WARRANTY_FETCH_BY_CURSOR = "WARRANTY_FETCH_BY_CURSOR"

export const FETCH_ITEMS = "FETCH_ITEMS"
export const GET_WARRANTIES = "GET_WARRANTIES"
export const PAGINATE_WARRANTIES = "PAGINATE_WARRANTIES"
export const FIND_WARRANTY = "FIND_WARRANTY"
export const SAVE_WARRANTY = "SAVE_WARRANTY"
export const GET_RETURNS = "GET_RETURNS"
export const RETURNS_MODAL_TOGGLE = "RETURNS_MODAL_TOGGLE"

export const FETCH_GENERATED_SERIALS = "FETCH_GENERATED_SERIALS"
export const FETCH_SERIAL_NUMBER = "FETCH_SERIAL_NUMBER"
export const SAVE_SERIAL_NUMBERS = "SAVE_SERIAL_NUMBERS"

export const FETCH_SERIAL_NUMBERS_V2 = "FETCH_SERIAL_NUMBERS_V2"
export const EDIT_SERIAL_NUMBER = "EDIT_SERIAL_NUMBER"
export const GET_SERIAL_NUMBER = "GET_SERIAL_NUMBER"

export const MODAL_OPEN = "MODAL_OPEN"
export const MODAL_CLOSE = "MODAL_CLOSE"
export const MODAL_TOGGLE = "MODAL_TOGGLE"
export const MODAL_EDIT_TOGGLE = "MODAL_EDIT_TOGGLE"

export const PRINTER_ONLINE = "PRINTER_ONLINE"
export const PRINTER_OFFLINE = "PRINTER_OFFLINE"
export const PRINTER_PRINT_SERIAL = "PRINTER_PRINT_SERIAL"
export const PRINTER_PRINT_BATCH = "PRINTER_PRINT_BATCH"
export const PRINTER_START_PRINT = "PRINTER_START_PRINT"
export const PRINTER_FINISH_PRINT = "PRINTER_FINISH_PRINT"
export const PRINTER_ERROR = "PRINTER_ERROR"
