import React, { useState, useEffect } from "react"
import { ModalBody, Form, Alert } from "reactstrap"
import FormRow from "components/FormRow"
import { useForm } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

import Modal from "components/EmptyModal"
import ServicePartsModalFooter from "components/ServicePartsModalFooter"
import API from "API"

import useAuthorContext from "hooks/useAuthorContext"

const schema = yup.object().shape({
  name: yup.string().required("Nazwa części jest wymagana"),
  code: yup.number().positive().typeError("Kod części jest niepoprawny"),
  count: yup
    .number()
    .typeError("Ilość musi być liczbą")
    .required("Proszę wpisać ilość części")
    .test("not-negative-number", "Wartość musi być w przedziale 0-9999+", value => value >= 0)
})

const UpdateServicePartModal = ({ isOpen, toggle, onSave = () => {}, updateProps = false }) => {
  const {
    handleSubmit,
    formState: { errors },
    // reset,
    setValue,
    control
  } = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: true
  })

  const [author] = useAuthorContext()

  const [isError, setIsError] = useState(false)
  const [currentPartCount, setCurrentPartCount] = useState(false)

  //  on submit handler
  const onSubmit = async data => {
    if (!updateProps) {
      return
    }
    const { name, code, count } = data
    const newPartCount = currentPartCount + count

    const run = async function () {
      let [task1, task2] = await Promise.all([
        // API.checkServicePartForId(code),
        API.updateServicePart(code, name, newPartCount, author),
        API.addServicePartHistory(code, name, "UPDATE", newPartCount, author, currentPartCount)
      ])
      return task1 && task2 ? Promise.resolve() : Promise.reject("Error")
    }

    run()
      .then(response => {
        // reset() //  reset form
        onSave() // fire onSave to reload data
        toggle() //  close modal
      })
      .catch(err => {
        console.log(err)
        setIsError("Wystąpił błąd, spróbuj ponownie")
      })
  }

  useEffect(() => {
    if (!updateProps) {
      return
    }

    const { partName, partCode } = updateProps
    setValue("name", partName)
    setValue("code", partCode)
    setCurrentPartCount(parseInt(updateProps?.partCount))
  }, [updateProps, setValue])

  return (
    <Modal isOpen={isOpen} toggle={toggle} title="Uaktualnij część serwisową" autoFocus={true}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {isError && <Alert color="danger">{isError}</Alert>}
        <ModalBody>
          <FormRow
            id="name"
            title="Nazwa części"
            // defaultValue={updateProps?.partName}
            control={control}
            errors={errors}
            addEditIcon={true}
            readOnly={true}
          />
          <FormRow
            id="code"
            title="Kod"
            // defaultValue={updateProps.partCode}
            control={control}
            errors={errors}
            readOnly={true}
          />
          <FormRow
            id="count"
            type="number"
            title="Dodawana ilość"
            placeholder="ilość części"
            // register={register("count")}
            control={control}
            errors={errors}
          />
        </ModalBody>
        <ServicePartsModalFooter />
      </Form>
    </Modal>
  )
}

export default UpdateServicePartModal
