import React from "react"
import { Button } from "reactstrap"
import { connect } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPrint, faSpinner } from "@fortawesome/free-solid-svg-icons"

import { printSerialNumber, printBatchSerials } from "store/actions/printer"

const PrintBarcodeBtn = props => {
  const { barcode, printing, printSerialNumber, printBatchSerials, printerStatus, printBatch = false } = props
  const isPrintingThisBarcode = printing.indexOf(barcode) > -1
  const icon = isPrintingThisBarcode ? faSpinner : faPrint
  const handleOnClick = () =>
    printBatch
      ? window.confirm(`Czy na pewno chcesz wydrukować ${barcode.length} kodów kreskowych ?`)
        ? printBatchSerials(barcode)
        : false
      : printSerialNumber(barcode)

  return (
    <Button
      color={isPrintingThisBarcode ? "success" : "info"}
      disabled={!printerStatus || isPrintingThisBarcode}
      onClick={handleOnClick}
      size="sm"
    >
      <FontAwesomeIcon icon={icon} pulse={isPrintingThisBarcode} /> Drukuj
    </Button>
  )
}

const mapStateToProps = state => ({
  printerStatus: state.printer.available,
  printing: state.printer.printing
})

const mapDispatchToProps = dispatch => ({
  printSerialNumber: serialNumber => dispatch(printSerialNumber(serialNumber)),
  printBatchSerials: serialNumbers => dispatch(printBatchSerials(serialNumbers))
})

export default connect(mapStateToProps, mapDispatchToProps)(PrintBarcodeBtn)
