import React from "react"
import { Table } from "reactstrap"
import { connect } from "react-redux"
import moment from "moment"
import { fetchItems } from "../../store/actions/returns"
import { productWarrantyTimeFormat } from "../../constants/product"
import { defaultItemsPerPage } from "../../constants/ui"
import serialNumberParser from "utils/serialNumberParser"

const WarrantyList = props => {
  React.useEffect(() => {
    props.fetchItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.itemsPerPage])

  const mapDataToRow = data => {
    const { serialNumber, returnDate } = data
    const returnDateFormated = moment.unix(returnDate.seconds).format(productWarrantyTimeFormat)
    const { productModel, productColor } = serialNumberParser(serialNumber)
    return (
      <tr key={serialNumber}>
        <td>{serialNumber}</td>
        <td>{`${productModel.title} (${productColor.name})`}</td>
        <td>{returnDateFormated}</td>
      </tr>
    )
  }

  return (
    <React.Fragment>
      {props.loading ? (
        <div className="loading">Loading data...</div>
      ) : (
        <React.Fragment>
          <Table bordered hover striped>
            <thead>
              <tr>
                <th>Numer seryjny</th>
                <th>Model</th>
                <th>Data zwrotu</th>
              </tr>
            </thead>
            <tbody>
              {props.items.length > 0 ? (
                props.items.map(mapDataToRow)
              ) : (
                <tr>
                  <td colSpan="5">Brak danych</td>
                </tr>
              )}
            </tbody>
          </Table>
        </React.Fragment>
      )}
      {/* <WarrantyEditModal /> */}
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  items: state.returns.items,
  itemsPerPage: defaultItemsPerPage,
  cursor: state.warranty.cursor,
  loading: state.appState.loading
})

const mapDispatchToProps = dispatch => ({
  fetchItems: () => dispatch(fetchItems())
})

export default connect(mapStateToProps, mapDispatchToProps)(WarrantyList)
