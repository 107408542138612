import React from "react"
import { withFormik } from "formik"
import * as yup from "yup"
// import PropTypes from "prop-types";
import { Col, Form, FormGroup, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { productModels, productColors, productBatch } from "../../constants/product"
import { createSerialNumber } from "utils/serialNumberParser"

const initialValues = {
  productModel: productModels[0].serialPart,
  productColor: productColors[0].serialPart.toString(),
  productBatch: productBatch[0].toString(),
  serialNumberCount: 1
}

const validationSchema = yup.object().shape({
  productModel: yup.string().required(),
  productColor: yup.string().required(),
  productBatch: yup.string().required(),
  serialNumberCount: yup.number().min(1).max(1000).required()
})

const SerialNumberPopover = props => {
  const { values, errors, handleChange, handleBlur, isValid, resetForm, isPopoverOpen = false, popOverToggle } = props

  const generateSerial = () => {
    const { productModel, productColor, productBatch, serialNumberCount } = values

    //  todo: dodac logike do sciagania kolejnego numeru seryjnego z bazy
    const lastSerialId = 194

    let serialNumbers = []
    for (let i = 0; i < serialNumberCount; i++) {
      serialNumbers.push(
        createSerialNumber({
          productModel,
          productColor,
          productBatch,
          serialNumber: (lastSerialId + i + 1).toString()
        })
      )
    }
  }

  return (
    <Modal
      isOpen={isPopoverOpen}
      toggle={popOverToggle}
      trigger="click"
      fade={false}
      size="lg"
      onClosed={() => resetForm(initialValues)}
    >
      <ModalHeader>Generuj numery seryjne</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup row>
            <Label for="productModel" sm={2}>
              Model
            </Label>
            <Col sm={10}>
              {productModels.map(({ serialPart, title }) => (
                <FormGroup check key={serialPart} inline>
                  <Label>
                    <Input
                      type="radio"
                      name="productModel"
                      id={serialPart}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={serialPart}
                      autoComplete="off"
                      defaultChecked={serialPart === productModels[0].serialPart}
                    />{" "}
                    {title}
                  </Label>
                </FormGroup>
              ))}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="productColor" sm={2}>
              Kolor
            </Label>
            <Col sm={10}>
              {productColors.map(({ name, serialPart }) => (
                <FormGroup check key={serialPart} inline>
                  <Label>
                    <Input
                      type="radio"
                      name="productColor"
                      id={serialPart}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={serialPart}
                      autoComplete="off"
                      defaultChecked={serialPart === productColors[0].serialPart}
                    />{" "}
                    {name}
                  </Label>
                </FormGroup>
              ))}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="productBatch" sm={2}>
              Nr partii
            </Label>
            <Col sm={10}>
              {productBatch.map(value => (
                <FormGroup check key={value} inline>
                  <Label>
                    <Input
                      type="radio"
                      name="productBatch"
                      id={value}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={value}
                      autoComplete="off"
                      defaultChecked={value === 0}
                    />
                    {` ${value}`}
                  </Label>
                </FormGroup>
              ))}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="serialNumberCount" sm={2}>
              Ilość
            </Label>
            <Col sm={10}>
              <FormGroup check inline>
                <Label>
                  <Input
                    type="number"
                    name="serialNumberCount"
                    placeholder="aktualny numer seryjny"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.serialNumberCount}
                    autoComplete="off"
                    maxLength="5"
                    valid={errors["serialNumberCount"] === undefined}
                    invalid={errors["serialNumberCount"] !== undefined || values.serialNumberCount === ""}
                  />
                </Label>
              </FormGroup>
            </Col>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button type="submit" color="primary" disabled={!isValid} onClick={generateSerial}>
          Generuj
        </Button>{" "}
        <Button color="danger" onClick={popOverToggle}>
          Anuluj
        </Button>
      </ModalFooter>
    </Modal>
  )
}

// SerialNumberPopover.propTypes = {
//   isPopoverOpen: PropTypes.bool.isRequired,
//   popOverToggle: PropTypes.func.isRequired,
//   onSerialGeneration: PropTypes.func.isRequired
// };

const SerialNumberGenerator = withFormik({
  mapPropsToValues: () => initialValues,
  validationSchema: validationSchema,
  displayName: "SerialNumberWizardForm"
})(SerialNumberPopover)

export default SerialNumberGenerator
