import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/database"
import "firebase/compat/firestore"

export const firebaseConfig = {
  appId: "steamaster-serwis",
  apiKey: "AIzaSyD4DqkMJZc0LQoB8WcGesVc_CVQaRo16pE",
  authDomain: "steamaster-serwis.firebaseapp.com",
  databaseURL: "https://steamaster-serwis.firebaseio.com",
  projectId: "steamaster-serwis",
  storageBucket: "steamaster-serwis.appspot.com",
  messagingSenderId: "794374083520"
}

firebase.initializeApp(firebaseConfig)

export const auth = firebase.auth()
export const provider = new firebase.auth.GoogleAuthProvider()
export const db = firebase.firestore()

if (window.location.hostname === "localhost") {
  db.settings({
    host: "localhost:8080",
    ssl: false,
    merge: true
  })
  auth.useEmulator("http://localhost:9099")
}

auth.languageCode = "pl"
auth.setPersistence(firebase.auth.Auth.Persistence.SESSION)

export default firebase
