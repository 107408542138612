import axios from "axios"
import { MongoAPIHost } from "constants/hosts"

const MongoApiCurrentHost = MongoAPIHost
// const MongoApiCurrentHost = "http://localhost:3001"

const MongoAPI = {
  fetchNextRepairId: async () => {
    const response = await axios.get(`${MongoApiCurrentHost}/getNextRepairId`)
    return response.data.nextRepairId
  },
  saveRepair: async (repairId = null, repairData = {}) => {
    if (repairId !== null) {
      //    update repair with ID
    } else {
      //    save new repair
      //    TODO: error handling
      const response = await axios.post(`${MongoApiCurrentHost}/saveRepair`, repairData)
    }
  }
}

export default MongoAPI
