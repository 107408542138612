import * as actions from "./actionTypes"

export const printerOnline = () => ({
  type: actions.PRINTER_ONLINE
})

export const printerOffline = () => ({
  type: actions.PRINTER_OFFLINE
})

export const printSerialNumber = serialNumber => ({
  type: actions.PRINTER_PRINT_SERIAL,
  payload: serialNumber
})

export const printBatchSerials = serialNumbers => ({
  type: actions.PRINTER_PRINT_BATCH,
  payload: serialNumbers
})

export const printerStartPrint = serialNumber => ({
  type: actions.PRINTER_START_PRINT,
  payload: serialNumber
})

export const printerFinishPrint = serialNumber => ({
  type: actions.PRINTER_FINISH_PRINT,
  payload: serialNumber
})

export const printerError = (serialNumber, error) => ({
  type: actions.PRINTER_ERROR,
  payload: {
    serialNumber,
    error
  }
})
