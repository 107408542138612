import API from "../../API"
import * as warrantyActions from "./actionTypes"
import * as AppState from "./appState"
import { batch } from "react-redux"

export const openModal = () => ({
  type: warrantyActions.WARRANTY_MODAL_OPEN
})

export const closeModal = () => ({
  type: warrantyActions.WARRANTY_MODAL_CLOSE
})

export const toggleModal = () => ({
  type: warrantyActions.WARRANTY_MODAL_TOGGLE
})

export const toggleEditModal = () => ({
  type: warrantyActions.WARRANTY_MODAL_EDIT_TOGGLE
})

export const addWarranty = values => dispatch => {
  API.saveWarranty(values)
}

export const warrantiesFetched = items => ({
  type: warrantyActions.GET_WARRANTY_ITEMS_SUCCESS,
  payload: items
})

export const editWarranty = warrantyData => ({
  type: warrantyActions.EDIT_WARRANTY_ITEM,
  payload: warrantyData
})

export const warrantyToEdit = item => async dispatch => {
  await batch(() => {
    dispatch({
      type: warrantyActions.EDIT_WARRANTY_ITEM,
      payload: item
    })
    dispatch({
      type: warrantyActions.WARRANTY_MODAL_OPEN
    })
  })
}

export const fetchWarranties = () => dispatch =>
  dispatch(AppState.loading())
    .then(() =>
      dispatch({
        type: warrantyActions.GET_WARRANTIES,
        meta: {
          collection: "warranties"
        }
      })
    )
    .then(() => dispatch(AppState.done()))

export const findWarranty = serialNumber => dispatch => {
  return dispatch(AppState.loading())
    .then(() =>
      dispatch({
        type: warrantyActions.FIND_WARRANTY,
        payload: [serialNumber],
        meta: {
          collection: "warranties",
          method: "find",
          searchKey: "serialNumber",
          searchTerm: serialNumber,
          orderBy: "purchaseDate"
        }
      })
    )
    .then(() => dispatch(AppState.done()))
}

export const saveWarranty = data => async dispatch =>
  dispatch(AppState.loading())
    .then(() =>
      dispatch({
        type: warrantyActions.SAVE_WARRANTY,
        payload: data,
        meta: {
          collection: "warranties",
          method: "saveItem"
        }
      }).then(() => dispatch(closeModal()))
    )
    .then(() => dispatch(AppState.done()))

export const updateWarranty = data => async dispatch => {
  dispatch(AppState.loading())
  try {
    await API.updateWarranty(data)
    dispatch(toggleEditModal())
  } catch (err) {
    console.log(err)
  }
  dispatch(AppState.done())
}

export const findBySerialNumber = serialNumber => async dispatch => {
  // console.log("searching for serial key")
  try {
    const items = await API.findWarranties(serialNumber)
    dispatch(warrantiesFetched(items))
  } catch (err) {
    console.log(err)
  }
}

export const paginateResults =
  (direction = "next", cursor) =>
  dispatch =>
    dispatch(AppState.loading())
      .then(() =>
        dispatch({
          type: warrantyActions.PAGINATE_WARRANTIES,
          meta: {
            collection: "warranties",
            method: "paginate",
            cursor,
            direction
          }
        })
      )
      .then(() => dispatch(AppState.done()))

export const changeItemsPerPage = itemsPerPage => ({
  type: warrantyActions.WARRANTY_CHANGE_ITEMS_PER_PAGE,
  payload: parseInt(itemsPerPage)
})

export const fetchByCursor = cursor => ({
  type: warrantyActions.WARRANTY_FETCH_BY_CURSOR,
  payload: cursor
})
