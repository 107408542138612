export const servicePartsHistoryFormat = "DD/MM (HH:mm)"
export const servicePartsHistoryFormatForMerge = "DDMMHHmm"
export const servicePartsPeopleBarcodes = [
  {
    name: "Piotrek",
    barcode: "PERSON-S1"
  },
  {
    name: "Roman",
    barcode: "PERSON-S2"
  },
  {
    name: "Patryk",
    barcode: "PERSON-S3"
  },
  {
    name: "Uszkodzony",
    barcode: "PERSON-BROKEN"
  },
  {
    name: "Biuro",
    barcode: "PERSON-OFFICE"
  },
  {
    name: "Sprzedaż",
    barcode: "PERSON-SALES"
  },
  {
    name: "Dostawa",
    barcode: "PERSON-SHIPMENT"
  }
]

export const servicePartsBarcodeConfirmAction = "SAVE"
