import { GET_RETURNS, MODAL_TOGGLE } from "../actions/actionTypes"
import { defaultItemsPerPage } from "../../constants/ui"

const initialState = {
  isModalOpen: false,
  items: [],
  itemsPerPage: defaultItemsPerPage
}

const returnsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_RETURNS:
      return {
        ...state,
        items: payload
      }
    case MODAL_TOGGLE:
      return {
        ...state,
        isModalOpen: !state.isModalOpen
      }
    default:
      return state
  }
}

export default returnsReducer
