import React from "react"
import { Container, Row, Col, Button } from "reactstrap"
import firebase, { provider } from "../../firebase"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGoogle } from "@fortawesome/free-brands-svg-icons"

import "./firebaseui.css"
import "./style.scss"

const LoginForm = props => {
  const handleSignInEmail = () =>
    firebase
      .auth()
      .signInWithEmailAndPassword("magda@test.com", "12341234")
      .then(userCredential => {
        console.log(userCredential)
      })
  const handleSignInGoogle = () => firebase.auth().signInWithPopup(provider)

  return (
    <Container>
      <Row>
        <Col>
          <div className="loginContainer">
            <h2>SteaMaster Serwis Login</h2>
            <div className="buttons">
              <Button onClick={handleSignInEmail}>Zaloguj przy użyciu hasła</Button>
              <Button color="danger" onClick={handleSignInGoogle}>
                Zaloguj się przez Google <FontAwesomeIcon icon={faGoogle} />
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default LoginForm
