import * as actions from "../actions/actionTypes"
import { defaultItemsPerPage } from "../../constants/ui"

const initialState = {
  items: [],
  item: null,
  itemsPerPage: defaultItemsPerPage
}

const serialNumbersV2 = (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.FETCH_SERIAL_NUMBERS_V2:
      return {
        ...state,
        items: payload
      }
    case actions.FETCH_SERIAL_NUMBER:
      return {
        ...state,
        items: payload
      }
    case actions.GET_SERIAL_NUMBER:
      return {
        ...state,
        item: payload
      }

    default:
      return state
  }
}

export default serialNumbersV2
