import { calculateSerialCheckDigit } from "utils/serialNumberParser"

export const defaultDateShortFormat = "DD/MM/YY"
export const repairsCreatedAtDateFormat = "dddd DD/MM/YY HH:mm"
export const createdShortDateFormat = "DD/MM/YY HH:mm"
export const repairsStatuses = {
  NEW: "NEW",
  IN_PROGRESS: "IN_PROGRESS",
  DONE: "DONE",
  // WAITING_FOR_PAYMENT: "WAITING_FOR_PAYMENT",
  SENT: "SENT",
  UTILIZATION: "UTILIZATION"
}
export const repairsStatusesName = {
  NEW: "Oczekująca",
  IN_PROGRESS: "W trakcie naprawy",
  DONE: "Zakończona",
  // WAITING_FOR_PAYMENT: "Oczekuje na płatność",
  SENT: "Wysłano",
  UTILIZATION: "Utylizacja"
}

export const mapStatusToName = status => repairsStatusesName[status]
export const mapStatusToColor = status => {
  switch (status) {
    case repairsStatuses.NEW:
      return "info"
    case repairsStatuses.IN_PROGRESS:
      return "warning"
    case repairsStatuses.DONE:
      return "secondary"
    // case repairsStatuses.WAITING_FOR_PAYMENT:
    //   return "danger"
    case repairsStatuses.UTILIZATION:
      return "trash"
    default:
      return "light"
  }
}

export const validateSerialNumber = num => {
  if (num.length !== 12) {
    return -1
  }

  return `${num}${calculateSerialCheckDigit(num)}`
}
