import { useState, useEffect } from "react"
import { Table } from "reactstrap"
import { repairsStatusesName } from "constants/repairs"
import { useParams } from "react-router-dom"
import moment from "moment"
import API from "API"

const RepairsHistory = () => {
  const [repairsList, setRepairsList] = useState([])
  let { serialNumber } = useParams()

  const fetchRepairs = async () => {
    await API.fetchRepairsForSerialNumber(serialNumber, setRepairsList)
  }

  useEffect(() => {
    fetchRepairs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="content-section service-parts-history-container">
      <h3>
        Historia napraw dla numeru seryjnego <strong>{serialNumber}</strong>
      </h3>
      {/* <div>Ilość napraw: {repairsList.length}</div> */}
      {repairsList.length === 0 && <div>Brak napraw dla podanego numeru seryjnego</div>}
      {repairsList.length > 0 && (
        <>
          <Table bordered hover striped>
            <thead>
              <tr>
                <th>Numer naprawy</th>
                <th>Data rozpoczęcia</th>
                <th>Data zakończenia</th>
                <th>Data wysłania</th>
                <th>Status naprawy</th>
              </tr>
            </thead>
            <tbody>
              {repairsList.map((row, i) => (
                <tr key={i}>
                  <td>R{row.id}</td>
                  <td>{moment.unix(row.createdAt?.seconds).format("YYYY-MM-DD")}</td>
                  <td>
                    {row.doneAt !== undefined
                      ? moment.unix(row.doneAt?.seconds).format("YYYY-MM-DD")
                      : "Brak daty zakończenia"}
                  </td>
                  <td>
                    {row.sentAt !== undefined
                      ? moment.unix(row.sentAt?.seconds).format("YYYY-MM-DD")
                      : "Brak daty wysłania"}
                  </td>
                  <td>{repairsStatusesName[row.status]}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </div>
  )
}

export default RepairsHistory
