import React, { useState } from "react"
import { Nav, NavItem, Button } from "reactstrap"
import styled from "styled-components"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMagic, faPrint, faBars } from "@fortawesome/free-solid-svg-icons"

import PrintSerialNumberWizard from "components/PrintSerialNumberWizard"

import "./style.scss"

const NavContainer = styled.div`
  margin: 10px 0;
`

const SerialNumbersNavigation = props => {
  const [printSerialIsOpen, setPrintSerialIsOpen] = useState(false)
  const [useTextarea, setUseTextarea] = useState(false)

  const togglePrintSerialWindow = () => {
    setPrintSerialIsOpen(!printSerialIsOpen)
    setUseTextarea(false)
  }

  const togglePrintSerialWindowWithTextarea = () => {
    setUseTextarea(true)
    setPrintSerialIsOpen(!printSerialIsOpen)
  }

  return (
    <>
      <NavContainer className="serial-numbers-nav">
        <Nav>
          <NavItem>
            <Button color="success" to="/serialNumbers/new" tag={Link}>
              <FontAwesomeIcon icon={faMagic} /> Generuj numery seryjne
            </Button>
          </NavItem>
          <NavItem>
            <Button color="info" onClick={togglePrintSerialWindow}>
              <FontAwesomeIcon icon={faPrint} /> Wydrukuj numer seryjny
            </Button>
          </NavItem>
          <NavItem>
            <Button color="secondary" onClick={togglePrintSerialWindowWithTextarea}>
              <FontAwesomeIcon icon={faBars} /> Wydrukuj wiele numerów
            </Button>
          </NavItem>
        </Nav>
      </NavContainer>
      <PrintSerialNumberWizard
        isOpen={printSerialIsOpen}
        toggle={togglePrintSerialWindow}
        isTextAreaVisible={useTextarea}
      ></PrintSerialNumberWizard>
    </>
  )
}

export default SerialNumbersNavigation
