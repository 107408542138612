import { createStore, applyMiddleware } from "redux"

import thunk from "redux-thunk"
import { composeWithDevTools } from "redux-devtools-extension"
import rootReducer from "./reducers"
import apiMiddleware from "./middleware/apiMiddleware"
import printerMiddleware from "./middleware/printerMiddleware"
// import logger from "./middleware/logger";

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || composeWithDevTools

const middlewareList = [thunk, apiMiddleware, printerMiddleware /*, logger*/]

const store = createStore(rootReducer, storeEnhancers(applyMiddleware(...middlewareList)))

export default store
