import React, { useState } from "react"
import { connect } from "react-redux"
import { HotKeys } from "react-hotkeys"
import { Link } from "react-router-dom"
import {
  Navbar,
  NavLink,
  NavItem,
  NavbarBrand,
  Nav,
  NavbarToggler,
  NavbarText,
  Collapse,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faWrench, faFileAlt, faBarcode, faToolbox, faChartBar } from "@fortawesome/free-solid-svg-icons"
import { fetchWarranties, addWarranty, openModal } from "store/actions/warranty"
import UserActions from "./UserActions"
import PrinterStatus from "components/PrinterStatus"
import MongoDBStatus from "components/MongoDBStatus"

const AppNavBar = props => {
  const hotkeysHandles = {
    NEW_WARRANTY: () => props.openModal()
  }

  const [isOpen, setIsOpen] = useState(false)
  const [show, setShow] = useState(false)

  const showDropdown = e => {
    setShow(!show)
  }
  const hideDropdown = e => {
    setShow(false)
  }

  const toggleCollapse = () => setIsOpen(!isOpen)

  return (
    <HotKeys handlers={hotkeysHandles}>
      <Navbar color="light" light expand="md">
        <NavbarBrand>Serwis</NavbarBrand>
        <NavbarToggler onClick={toggleCollapse} />
        <Collapse navbar isOpen={isOpen}>
          <Nav className="me-auto" navbar>
            <NavItem>
              <NavLink to="/warranty" tag={Link}>
                <FontAwesomeIcon icon={faFileAlt} /> Gwarancje
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/serialNumbers" tag={Link}>
                <FontAwesomeIcon icon={faBarcode} /> Numery seryjne
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/serwis" tag={Link}>
                <FontAwesomeIcon icon={faWrench} /> Naprawy
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/service-parts" tag={Link}>
                <FontAwesomeIcon icon={faToolbox} /> Części
              </NavLink>
            </NavItem>
            <UncontrolledDropdown inNavbar nav isOpen={show} onMouseEnter={showDropdown} onMouseLeave={hideDropdown}>
              <DropdownToggle caret nav>
                <FontAwesomeIcon icon={faChartBar} /> Raporty
              </DropdownToggle>
              <DropdownMenu end={true}>
                <DropdownItem tag={Link} to="/reports/service-parts-history">
                  Raport użycia części zamiennych
                </DropdownItem>
                <DropdownItem tag={Link} to="/reports/repairs-history" disabled={true}>
                  Raport przebiegu napraw
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>

          <NavbarText tag={"div"} className="d-inline-flex flex-md-row justify-content-end align-items-center">
            <MongoDBStatus />
            <PrinterStatus />
            <UserActions />
          </NavbarText>
        </Collapse>
      </Navbar>
    </HotKeys>
  )
}

const mapDispatchToProps = dispatch => ({
  fetchWarranties: lastId => dispatch(fetchWarranties(lastId)),
  openModal: () => dispatch(openModal()),
  addWarranty
})

export default connect(null, mapDispatchToProps)(AppNavBar)
