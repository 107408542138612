import React from "react"
import { Table, Button, Badge } from "reactstrap"
import { connect } from "react-redux"
import moment from "moment"
import WarrantyEditModal from "components/Warranty/editModal"
import { fetchItems, editItem } from "store/actions/serialNumbersV2"
import { productWarranty } from "constants/product"
import parseSerialNumber from "utils/serialNumberParser"
import { timestampToDate } from "utils/dateFormatter"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye } from "@fortawesome/free-solid-svg-icons"
import { Link } from "react-router-dom"

const SerialNumbersList = props => {
  const { fetchItems } = props

  React.useEffect(() => {
    fetchItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const ListActionItem = ({ id, item }) => {
    return (
      <React.Fragment>
        <Button size="sm" color="primary" to={`/numery-seryjne/${id}/edit`} tag={Link}>
          Edytuj
        </Button>
        &nbsp;
        <Button size="sm" color="info" to={`/numery-seryjne/${id}/view`} tag={Link}>
          <FontAwesomeIcon icon={faEye} /> Zobacz
        </Button>
      </React.Fragment>
    )
  }

  const mapDataToRow = data => {
    const { id, warranty } = data
    const { productModel } = parseSerialNumber(id)
    const { purchaseDate, warrantyLength } = warranty[0]

    const { title } = productWarranty.filter(item => item.count === parseInt(warrantyLength)).pop()
    const warrantyDateLength = moment.unix(purchaseDate.seconds).add(warrantyLength, "months")

    const warrantyIsActive = moment().isSameOrBefore(warrantyDateLength)

    return (
      <tr key={id}>
        <td>{id}</td>
        <td>{productModel["title"]}</td>
        <td>
          <Badge color="secondary">{title}</Badge>
        </td>
        <td>
          <Badge color={warrantyIsActive ? "success" : "danger"}>{warrantyIsActive ? "Aktywna" : "Wygasła"}</Badge>
        </td>
        <td>{timestampToDate(purchaseDate.seconds)}</td>
        <td>
          <ListActionItem id={id} item={data} />
        </td>
      </tr>
    )
  }

  return (
    <React.Fragment>
      <Table bordered hover striped>
        <thead>
          <tr>
            <th>Numer seryjny</th>
            <th>Model / Kolor</th>
            <th>Gwarancja</th>
            <th>Status</th>
            <th>Data wystawienia gwarancji</th>
            <th>Operacje</th>
          </tr>
        </thead>
        <tbody>
          {props.items.length > 0 ? (
            props.items.map(mapDataToRow)
          ) : (
            <tr>
              <td colSpan="5">Brak danych</td>
            </tr>
          )}
        </tbody>
      </Table>
      <WarrantyEditModal />
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  items: state.serialNumbersV2.items
})

const mapDispatchToProps = dispatch => ({
  fetchItems: () => dispatch(fetchItems()),
  editSerialNumber: item => dispatch(editItem(item))
})

export default connect(mapStateToProps, mapDispatchToProps)(SerialNumbersList)
