import { Container, Row, Col } from "reactstrap"
import { Route, Routes } from "react-router-dom"

import AppNavBar from "components/NavBar"
import Warranty from "components/Warranty"
import ServiceOrders from "components/ServiceOrders"
import SerialNumbersV2 from "components/SerialNumbersV2"
import SerialNumbersView from "components/SerialNumbersV2/view"
import SerialNumbersEdit from "components/SerialNumbersV2/edit"

import SerialNumbers from "pages/SerialNumbers/List"
import SerialNumbersForm from "pages/SerialNumbers/Form"
import ServiceParts from "pages/ServiceParts"
import ServicePartsHistory from "pages/ServicePartsHistory"
import Repairs from "pages/Repairs"
import RepairsHistory from "pages/RepairsHistory"
import RepairsHistoryForSerialNumber from "pages/RepairsHistoryForSerialNumber"

import { AuthorProvider } from "context/authorProvider"
import { ScannerProvider } from "context/scannerProvider"

import Returns from "components/Returns"
import Home from "components/Home"
import Loader from "components/Loader"
import Modal from "components/Modal"

const AppContainer = () => {
  return (
    <Container fluid>
      <Row>
        <Col>
          <AppNavBar />
        </Col>
      </Row>

      <Row>
        <ScannerProvider>
          <AuthorProvider>
            <Col>
              <Routes>
                {/* <Redirect exact from="/" to="warranty" /> */}
                {/* <Redirect exact from="" to="warranty" /> */}
                <Route exact path="/" element={<Home />} />
                <Route exact path="/warranty" element={<Warranty />} />
                <Route exact path="/returns" element={<Returns />} />
                <Route path="/serviceOrders" element={<ServiceOrders />} />
                <Route exact path="/serialNumbers" element={<SerialNumbers />} />
                <Route exact path="/serialNumbers/new" element={<SerialNumbersForm />} />

                {/* numery seryjne v2 */}
                <Route exact path="/numery-seryjne" element={<SerialNumbersV2 />} />
                <Route exact path="/numery-seryjne/:id/view" element={<SerialNumbersView />} />
                <Route exact path="/numery-seryjne/:id/edit" element={<SerialNumbersEdit />} />

                {/* naprawy */}
                <Route exact path="/serwis" element={<Repairs />} />

                {/* czesci */}
                <Route exact path="/service-parts" element={<ServiceParts />} />

                {/* raporty */}
                <Route path="/reports/service-parts-history" element={<ServicePartsHistory />} />
                <Route path="/reports/repairs-history" element={<RepairsHistory />} />
                <Route path="/reports/repairs-history/:serialNumber" element={<RepairsHistoryForSerialNumber />} />
              </Routes>
            </Col>
          </AuthorProvider>
        </ScannerProvider>
      </Row>
      <Loader />
      <Modal />
    </Container>
  )
}

export default AppContainer
