import { combineReducers } from "redux"
import appState from "./appState"
import warrantyReducer from "./warranty"
import returnsReducer from "./returns"
import serviceOrderReducer from "./serviceOrders"
import serialNumbersReducer from "./serialNumbers"
import serialNumbersV2Reducer from "./serialNumbersV2"
import printerReducer from "./printer"
import modalReducer from "./modal"

export default combineReducers({
  appState,
  modal: modalReducer,
  warranty: warrantyReducer,
  returns: returnsReducer,
  serviceOrders: serviceOrderReducer,
  serialNumbers: serialNumbersReducer,
  serialNumbersV2: serialNumbersV2Reducer,
  printer: printerReducer
})
