import { useEffect } from "react"

export default function useKeyPress(key, action, ctrlKey = false) {
  useEffect(() => {
    function onKeyUp(e) {
      if (e.key === key && e.ctrlKey === ctrlKey) action(e)
    }
    window.document.addEventListener("keyup", onKeyUp)
    return () => window.document.removeEventListener("keyup", onKeyUp)
  }, [key, action, ctrlKey])
}
