import React from "react"
import { HotKeys } from "react-hotkeys"
import { Provider } from "react-redux"
import { BrowserRouter as Router } from "react-router-dom"
import AuthCheck from "./AuthCheck"

import store from "./store/configureStore"
import keyMap from "./constants/keyMaps"

import "./App.css"

// if (process.env.NODE_ENV === "development") {
//   const httpsAgent = new https.Agent({
//     rejectUnauthorized: false
//   })
//   axios.defaults.httpsAgent = httpsAgent

//   console.log(process.env.NODE_ENV, `RejectUnauthorized is disabled.`)
// }

const browserHistory = Router.browserHistory

const App = () => (
  <Provider store={store}>
    <HotKeys keyMap={keyMap}>
      <Router history={browserHistory}>
        <AuthCheck />
      </Router>
    </HotKeys>
  </Provider>
)

export default App
