import React from "react"
import { FormGroup, FormText, Label } from "reactstrap"
import Datetime from "react-datetime"
import "moment/locale/pl"

import "react-datetime/css/react-datetime.css"

const PurchaseDateField = props => {
  const { field } = props

  if (field.value == undefined) {
    field.value = new Date()
  }

  return (
    <FormGroup>
      <Label>Data zakupu</Label>
      <Datetime
        dateFormat="DD/MM/YYYY"
        timeFormat="HH:mm"
        value={field.value}
        onChange={dateObj => props.form.setFieldValue(field.name, dateObj)}
        closeOnSelect={true}
      />
      <FormText>format: 31/12/2021 12:34</FormText>
    </FormGroup>
  )
}

export default PurchaseDateField
