import { useState, useEffect } from "react"
// import axios from "axios"

export default function useMongoStatus() {
  const [isOnline, setIsOnline] = useState(false)
  const MONGO_HOST = process.env.REACT_APP_MONGO_API_HOST || "http://192.168.1.200:3001"

  useEffect(() => {
    async function getMongoDBInfo() {
      let status = false
      try {
        let response = await fetch(`${MONGO_HOST}/healthcheck`, { referrerPolicy: "no-referrer" })
        status = response.status === 200
      } catch (err) {
        status = false
      }
      return status
    }

    getMongoDBInfo().then(status => {
      setIsOnline(status)
    })

    // eslint-disable-next-line
  }, [])

  return isOnline
}
