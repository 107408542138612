import React from "react"
import { Nav } from "reactstrap"
import styled from "styled-components"

const Container = styled.div`
  margin: 10px 0;
`

const NavContainer = props => {
  return (
    <Container>
      <Nav className={props.className ? props.className : ""}>{props.children}</Nav>
    </Container>
  )
}

export default NavContainer
