import includes from "lodash/includes"

import { APP_LOADING, APP_LOADING_DONE, APP_LOADING_ERROR, HANDLE_USER_STATE } from "./actionTypes"

export const loading = () => ({
  type: APP_LOADING
})

export const done = () => ({
  type: APP_LOADING_DONE
})

export const error = err => ({
  type: APP_LOADING_ERROR,
  payload: err
})

export const handleUserLogin = (user = null) => {
  let loggedInUser = null
  if (user && user.email) {
    const emailDomain = user.email.replace(/.*@/, "")

    if (includes(["xine.pl", "steamaster.pl", "savrin.pl"], emailDomain)) {
      loggedInUser = user
    }
  }
  return {
    type: HANDLE_USER_STATE,
    payload: loggedInUser
  }
}
