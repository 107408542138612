import { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Badge } from "reactstrap"
import styled from "styled-components"

import { repairsStatuses, repairsStatusesName, mapStatusToColor } from "constants/repairs"

const LegendBadge = styled(Badge)`
  font-size: 11pt;
  margin-right: 0.5em;
`

const RepairStats = ({ repairs = [] }) => {
  const [stats, setStats] = useState([])
  const calculateStats = items => {
    let stats = {}

    for (const { status } of items) {
      stats = {
        ...stats,
        [status]: stats[status] !== undefined ? stats[status] + 1 : 1
      }
    }

    setStats(stats)
  }

  useEffect(() => {
    if (repairs !== []) {
      calculateStats(repairs)
    }
  }, [repairs])

  const badges = Object.entries(repairsStatuses).map(([key, value], i) => (
    <LegendBadge key={i} color={mapStatusToColor(key)}>{`${repairsStatusesName[key]}: ${
      stats[key] !== undefined ? stats[key] : 0
    }`}</LegendBadge>
  ))

  return <div>{badges}</div>
}

RepairStats.propTypes = {
  repairs: PropTypes.array.isRequired
}

export default RepairStats
