import * as actions from "../actions/actionTypes"

const initialState = {
  isShowing: false,
  content: null,
  title: null
}

const modal = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case actions.MODAL_OPEN:
      return {
        ...state,
        isShowing: true,
        ...payload
      }

    case actions.MODAL_CLOSE:
      return {
        ...state,
        isShowing: false,
        ...payload
      }

    case actions.MODAL_TOGGLE:
      return {
        ...state,
        isShowing: !state.isShowing,
        ...payload
      }

    default:
      return state
  }
}

export default modal
