import React, { useState, useCallback } from "react"
import { ModalBody, Form, Alert } from "reactstrap"
import { useForm } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

import FormRow from "components/FormRow"
import Modal from "components/EmptyModal"
import ServicePartsModalFooter from "components/ServicePartsModalFooter"

import API from "../../API"

import useAuthorContext from "hooks/useAuthorContext"
import useScannerContext from "hooks/useScannerContext"

const NewServicePartModal = ({ isOpen, toggle, onSave = () => {} }) => {
  const [state] = useScannerContext()
  const [author] = useAuthorContext()

  //  schema
  const schema = yup.object().shape({
    name: yup.string().required("Nazwa części jest wymagana"),
    code: yup
      .number()
      .typeError("Kod części jest niepoprawny")
      .positive()
      .required()
      .test("id-exists", "Ten kod juz istnieje, proszę podać inny", value => {
        const idExists = checkServicePartIdIfExists(value)
        return idExists ? false : true //  return validation false if ID exists
      }),
    // .test("id-exists", "Ten kod juz istnieje, proszę podać inny"),
    count: yup.number().typeError("Ilość musi być liczbą").positive().integer().required()
  })

  //  hook-form
  const {
    handleSubmit,
    control,
    formState: { errors }
    // reset
  } = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: true
  })

  const checkServicePartIdIfExists = useCallback(
    code => {
      if (state.serviceParts.length === 0) {
        return false
      }

      return state.serviceParts.filter(e => parseInt(e.id) === code).reduce((p, cur) => cur, false)
    },
    [state.serviceParts]
  )

  const [isError, setIsError] = useState(false)

  const onSubmit = async data => {
    const { name, code, count } = data

    const run = async function () {
      let [task1, task2] = await Promise.all([
        // API.checkServicePartForId(code),
        API.saveServicePart(name, code, count, author),
        API.addServicePartHistory(code, name, "ADD", count, author, 0)
      ])
      return task1 && task2 ? Promise.resolve() : Promise.reject("Error")
    }

    run()
      .then(response => {
        // reset() //  reset form
        onSave() // fire onSave to reload data
        toggle() //  close modal
      })
      .catch(err => {
        console.log(err)
        setIsError("Wystąpił błąd, spróbuj ponownie")
      })
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} title="Nowa część serwisowa" autoFocus={true}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {isError && <Alert color="danger">{isError}</Alert>}
        <ModalBody>
          <FormRow id="name" title="Nazwa części" placeholder="nazwa części" control={control} errors={errors} />
          <FormRow id="code" title="Kod" placeholder="kod części" control={control} errors={errors} />
          <FormRow
            id="count"
            type="number"
            title="Ilość"
            placeholder="ilość części"
            control={control}
            errors={errors}
          />
        </ModalBody>
        <ServicePartsModalFooter />
      </Form>
    </Modal>
  )
}

export default NewServicePartModal
