import React from "react"
import { connect } from "react-redux"
import { saveItem } from "../../../store/actions/serialNumbers"
import { Breadcrumb, BreadcrumbItem } from "reactstrap"
import { Link } from "react-router-dom"
import SerialsBatchForm from "../../../forms/batchSerialsForm"

const SerialNumbersForm = props => (
  <div className="content-section">
    <h3>Generuj numery seryjne</h3>
    <Breadcrumb>
      <BreadcrumbItem>
        <Link to="/serialNumbers">Numery seryjne</Link>
      </BreadcrumbItem>
      <BreadcrumbItem active>Generator</BreadcrumbItem>
    </Breadcrumb>
    <SerialsBatchForm saveForm={props.saveForm} />
  </div>
)

const mapDispatchToProps = dispatch => ({
  saveForm: data => dispatch(saveItem(data))
})

export default connect(null, mapDispatchToProps)(SerialNumbersForm)
