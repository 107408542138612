import { FormGroup, Label, Col } from "reactstrap"

const EmptyFormRow = props => {
  const { title = "", labelSize = 3, inputSize = 9, ...otherProps } = props

  return (
    <FormGroup row>
      <Label sm={labelSize}>{title}</Label>
      <Col sm={inputSize}>{otherProps.children && otherProps.children}</Col>
    </FormGroup>
  )
}

export default EmptyFormRow
