import React, { useState, useCallback } from "react"
import { Controller } from "react-hook-form"
import {
  FormGroup,
  FormFeedback,
  Label,
  Col,
  InputGroup,
  // InputGroupAddon,
  InputGroupText,
  Input,
  Button
} from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit } from "@fortawesome/free-solid-svg-icons"

const FormRow = props => {
  const {
    id,
    title,
    type = "text",
    control = null,
    placeholder = "",
    classId = id,
    errors = null,
    addEditIcon = false,
    readOnly = false,
    labelSize = 2,
    inputSize = 10,
    prepend = null,
    ...otherProps
  } = props

  const [isReadOnly, setIsReadOnly] = useState(readOnly)
  const handleReadOnlyCallback = useCallback(() => setIsReadOnly(!isReadOnly), [isReadOnly])

  return (
    <FormGroup row>
      <Label for={id} sm={labelSize}>
        {title}
      </Label>
      <Col sm={inputSize}>
        <div className={addEditIcon ? "d-inline-flex w-100" : ""}>
          <Controller
            name={id}
            control={control}
            defaultValue={""}
            render={({ field, fieldState: { invalid } }) => {
              return prepend !== null ? (
                <InputGroup>
                  <InputGroupText>{prepend}</InputGroupText>
                  <Input
                    type={type}
                    name={id}
                    id={classId}
                    placeholder={placeholder}
                    invalid={invalid}
                    readOnly={isReadOnly}
                    {...field}
                    {...otherProps}
                  />
                </InputGroup>
              ) : (
                <Input
                  type={type}
                  name={id}
                  id={classId}
                  placeholder={placeholder}
                  invalid={invalid}
                  readOnly={isReadOnly}
                  {...field}
                  {...otherProps}
                />
              )
            }}
          />

          {addEditIcon && (
            <Button outline color="secondary" className="ml-sm-2" onClick={handleReadOnlyCallback}>
              <FontAwesomeIcon icon={faEdit} />
            </Button>
          )}
        </div>
        {errors[id] && errors[id].message && <FormFeedback className="d-flex">{errors[id].message}</FormFeedback>}
        {otherProps.children && otherProps.children}
      </Col>
    </FormGroup>
  )
}

export default FormRow
