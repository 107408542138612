import React, { useState } from "react"
import { Form, Button, ModalBody, ModalFooter, Spinner, Input } from "reactstrap"
import { useForm } from "react-hook-form"
import SerialNumberFieldWithWizard from "components/SerialNumberField"
import LoadingButton from "components/LoadingButton"
import styled from "styled-components"
import { printSerialNumber, printBatchSerialNumbers } from "API/printer"
import Modal from "components/EmptyModal"
import usePrinterStatus from "hooks/usePrinterStatus"

const formDefaultValues = {
  serialNumber: "",
  serialNumberTextArea: ""
}

const StyledLoadingButton = styled(LoadingButton)`
  margin-right: 10px;
`

const PrintSerialNumberWizard = props => {
  const serialInputRef = React.createRef()
  const { toggle, isOpen, isTextAreaVisible } = props
  const [isPrinting, setIsPrinting] = useState(false)
  const isPrinterAvailable = usePrinterStatus()

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onBlur",
    defaultValues: formDefaultValues
  })

  const {
    handleSubmit,
    trigger,
    reset,
    control,
    setValue,
    formState: { errors, isValid }
  } = methods

  const onSubmit = async (data, { printCount }) => {
    setIsPrinting(true)
    isTextAreaVisible
      ? await printBatchSerialNumbers(data?.serialNumberTextArea.replace(/\r\n/g, "\n").split("\n"), printCount)
      : await printSerialNumber(data?.serialNumber, printCount)
    setIsPrinting(false)
  }

  const handleModalOpened = () => {
    serialInputRef.current.focus()
    trigger()
  }

  const toggleForm = () => {
    reset()
    toggle()
  }

  const printCounts = [1, 2, 3, 4]

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      title={isTextAreaVisible ? "Wydruk numerów seryjnych" : "Wydruk numeru seryjnego"}
      onOpened={handleModalOpened}
    >
      <Form>
        <ModalBody>
          {isTextAreaVisible ? (
            <Input
              type="textarea"
              name="serialNumberTextArea"
              rows={10}
              placeholder="Numery seryjne"
              onChange={e => setValue("serialNumberTextArea", e.target.value.trim())}
              innerRef={serialInputRef}
            />
          ) : (
            <SerialNumberFieldWithWizard
              name="serialNumber"
              serialRef={serialInputRef}
              control={control}
              setValue={setValue}
              errors={errors}
            />
          )}
        </ModalBody>
        <ModalFooter className="d-flex flex-row justify-content-between">
          <div>
            {isPrinting === true && (
              <>
                <Spinner color="primary" size="sm">
                  Loading...
                </Spinner>{" "}
                Drukowanie...
              </>
            )}
          </div>
          <div>
            {printCounts.map(count => (
              <StyledLoadingButton
                key={count}
                color="primary"
                disabled={isPrinting || (!isTextAreaVisible && !isValid) || !isPrinterAvailable}
                onClick={handleSubmit(data => onSubmit(data, { printCount: count }))}
              >
                Wydrukuj &times; {count}
              </StyledLoadingButton>
            ))}
            <Button color="danger" onClick={toggleForm}>
              Anuluj
            </Button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

export default PrintSerialNumberWizard
